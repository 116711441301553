import { useMutation, useReactiveVar } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { apolloClient } from '@src/apolloClient'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { breakpoints } from '@src/constants/breakpoints'
import { useDiscounts } from '@src/hooks/useDiscounts/useDiscounts'
import { DiscountType } from '@src/hooks/useDiscounts/validation'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'
import { screenResolutionVar } from '@src/models/screenResolution'
import { apolloErrorParser } from '@src/utils/apolloErrorParser/apolloErrorParser'

import {
  ButtonContainer,
  Container,
  Header,
  CentredHeader,
  HeaderSmall,
  InnerContainer,
  Span,
  CreateAccountSpan,
  CreateAccountButton,
  ForgotPasswordButton,
  DiscountContainer,
  AcceptedContainer,
  ErrorContainer,
  CentredErrorHeader,
  InnerDiscountContainer,
  ErrorText,
} from './AcceptDiscount.styles'
import { acceptDiscountDocument } from './mutations/__generated__/acceptDiscount.graphql-interface'

import { loginDocument } from '../BurgerMenu/Login/mutations/__generated__/login.graphql-interface'
import { loginInputSchema } from '../BurgerMenu/Login/validation/loginStructure'
import { Register } from '../BurgerMenu/Register/Register'
import { Button } from '../Button'
import { ErrorPage } from '../Errors/ErrorPage'
import { TextInput } from '../Inputs'
import { LoadingSpinner } from '../Loaders/LoadingSpinner'
import { WarningTriangleSVG } from '../SVGS/WarningTriangleSVG'

export const AcceptDiscountModal: React.FC<{
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('discounts')
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const businessName = searchParams.get('businessName')
  const enrolmentKey = searchParams.get('enrolmentKey') as string // component only renders if enrolmentKey is present in search params
  const discountAmount = searchParams.get('percentage')
  const jwt = useReactiveVar(jwtVar)
  const marketplace = useMarketplace()
  const [discountAccepted, setDiscountAccepted] = useState(false)
  const [discountError, setDiscountError] = useState('')
  const [createAccount, setCreateAccount] = useState(false)
  const [loginError, setLoginError] = useState('')
  const { width } = useReactiveVar(screenResolutionVar)
  const { updateDiscount } = useDiscounts()
  const ERROR_DELAY = isOpen ? 4000 : 1000 // delay modal close so the user can see the error / success message

  const [acceptDiscount, { loading }] = useMutation(acceptDiscountDocument, {
    variables: {
      enrolmentKey: enrolmentKey,
    },
    onError: error => {
      const parsedErrors = apolloErrorParser(error)
      if (parsedErrors?.genericClientErrors?.length) {
        setDiscountError(
          parsedErrors?.genericClientErrors?.[0]?.message || t('discount_error')
        )
      }
    },
    onCompleted: ({ acceptDiscount: memberDiscount }) => {
      // assert discount has an associated business (the mutation _should_ throw an error if not, but just in case)
      if (!memberDiscount.restaurant) {
        setDiscountError(t('discount_error'))
        return
      }
      updateDiscount({
        type: DiscountType.MEMBER_DISCOUNT,
        data: {
          discountId: memberDiscount.id,
          businessId: memberDiscount.restaurant.id,
        },
      })
    },
  })

  const handleAcceptDiscount = async () => {
    try {
      await acceptDiscount({
        variables: {
          enrolmentKey: enrolmentKey,
        },
      })
      setDiscountAccepted(true)
    } catch (error: any) {
      setDiscountError(error.message ?? t('activation_problem'))
    }
  }

  useEffect(() => {
    void apolloClient.refetchQueries({
      include: 'active',
    })

    if (discountAccepted || discountError || loginError || !isOpen) {
      setTimeout(() => {
        if (!loginError) {
          navigate('/')
        } else {
          setLoginError('')
        }
      }, ERROR_DELAY)
    }
  }, [discountAccepted, discountError, loginError, isOpen])

  const [login, { loading: loginLoading }] = useMutation(loginDocument, {
    onCompleted(data) {
      jwtVar(data.login.token)
    },
    onError(e) {
      setLoginError(e.message)
    },
  })

  if (loading || loginLoading) return <LoadingSpinner />
  if (!businessName && !discountAccepted) return <ErrorPage />

  if (!jwt && createAccount)
    return (
      <>
        <Container>
          <HeaderSmall>
            {t('create_account_to_accept_discount', {
              businessName,
              discountAmount,
            })}
          </HeaderSmall>

          <CreateAccountButton
            type="button"
            onClick={() => setCreateAccount(false)}
          >
            {t('already_have_an_account')}{' '}
            <CreateAccountSpan>{t('sign_in')}</CreateAccountSpan>
          </CreateAccountButton>
          <br />
        </Container>
        <Register isDiscountModal={true} />
      </>
    )

  if (!jwt && !createAccount)
    return (
      <Container>
        <HeaderSmall>
          {t('sign_in_to_accept_discount', {
            businessName,
            discountAmount,
          })}
        </HeaderSmall>
        <CreateAccountButton
          type="button"
          onClick={() => setCreateAccount(true)}
        >
          {t('new_customer')}{' '}
          <CreateAccountSpan>{t('create_account')}</CreateAccountSpan>
        </CreateAccountButton>
        {loginError && <ErrorText>{loginError}</ErrorText>}

        <InnerContainer>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={toFormikValidationSchema(loginInputSchema)}
            validateOnChange
            validateOnBlur={false}
            onSubmit={values => {
              void login({
                variables: { ...values, marketplaceKey: marketplace.key },
              })
            }}
          >
            {({ submitForm }) => (
              <Form>
                <TextInput
                  name="email"
                  label={t('email_address')}
                  autoComplete="email"
                  required
                  focusOnLoad
                />

                <TextInput
                  type="password"
                  name="password"
                  label={t('password')}
                  autoComplete="current-password"
                  required
                />
                <ForgotPasswordButton
                  to="/?account=login&accountChild=reset-password"
                  passthroughQueryParams={false}
                >
                  {t('forgot_password')}
                </ForgotPasswordButton>
                <ButtonContainer>
                  <Button
                    type="button"
                    content={t('sign_in_and_accept')}
                    width={width > breakpoints.tablet ? '47.5%' : '100%'}
                    onClick={submitForm}
                  />
                  <br />
                  <Button
                    width={width > breakpoints.tablet ? '47.5%' : '100%'}
                    type="button"
                    content={t('close_modal')}
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  />
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </InnerContainer>
      </Container>
    )

  if (!discountAccepted && !discountError)
    return (
      <DiscountContainer
        onSubmit={e => {
          e.preventDefault()
          void handleAcceptDiscount()
        }}
      >
        <Header>
          {t('discount_enrolment', {
            businessName,
            discountAmount,
          })}
        </Header>

        <InnerDiscountContainer>
          <Span>{t('accept_discount')}</Span>
        </InnerDiscountContainer>
        <ButtonContainer>
          <Button
            type="submit"
            disabled={loading}
            content={t('accept')}
            width={'45%'}
          />

          <Button
            type="button"
            onClick={() => {
              setIsOpen(false)
            }}
            disabled={loading}
            content={t('cancel')}
            width={'45%'}
          />
        </ButtonContainer>
      </DiscountContainer>
    )

  return (
    <>
      {discountError ? (
        <ErrorContainer>
          <WarningTriangleSVG
            width={60}
            height={60}
            id={'accept-discount-warning'}
          />
          <CentredErrorHeader>{discountError}</CentredErrorHeader>
        </ErrorContainer>
      ) : (
        <AcceptedContainer>
          <LoaderCheck />
          <CentredHeader>{t('discount_accepted')}</CentredHeader>
        </AcceptedContainer>
      )}
    </>
  )
}
