import { useQuery } from '@apollo/client'
import { formatISO, subDays, startOfDay } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperType } from 'swiper/types'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { getMyOrdersHistoryDocument } from '@src/hooks/sharedQueries/orderHistoryQuery/queries/__generated__/getMyOrdersHistory.graphql-interface'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import { StatusBox } from '@src/pages/SingleOrder/StatusBox'

import {
  ActiveOrdersContainer,
  ArrowContainer,
  HeaderContainer,
  Order,
  SingleActiveOrderContainer,
} from './ActiveOrdersSlider.styles'

import { GroupHeader } from '../BurgerMenu/AccountMain/AccountNavigationLinks.styles'
import { IconButton } from '../Button/IconButton'
import { Carousel } from '../Carousel'
import { LoadingSpinner } from '../Loaders/LoadingSpinner'

const PAGE_SIZE = 5

const startOfTwoDaysAgoISO = formatISO(startOfDay(subDays(new Date(), 2)))

export const ActiveOrdersSlider: React.VFC<{
  isAccountPage: boolean
}> = ({ isAccountPage }) => {
  const { t } = useTranslation('navigationLinks')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [orderSwiperInstance, setOrderSwiperInstance] =
    useState<SwiperType | null>(null)
  const { route } = useAccountRouter()

  const { data, loading, error } = useQuery(getMyOrdersHistoryDocument, {
    variables: {
      input: { pageSize: PAGE_SIZE },
      endDate: startOfTwoDaysAgoISO,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return null
  }
  if (!data) {
    return null
  }

  const { edges: orders } = data.orders

  const scrollToNextOrder = () => {
    const indexToScrollTo = currentIndex + 1
    setCurrentIndex(indexToScrollTo)
    orderSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  const scrollToPreviousOrder = () => {
    const indexToScrollTo = currentIndex - 1
    setCurrentIndex(indexToScrollTo)
    orderSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  return (
    <>
      {orders.length > 0 && (
        <HeaderContainer>
          <GroupHeader>{t('my_orders')}</GroupHeader>
          {orders.length > 1 && (
            <ArrowContainer>
              <IconButton
                disabled={currentIndex === 0}
                onClick={() => {
                  if (currentIndex > 0) {
                    scrollToPreviousOrder()
                  }
                }}
                size={32}
                icon={'caretLeft'}
                alt={t('left')}
              />
              <IconButton
                disabled={currentIndex === orders.length - 1}
                onClick={() => {
                  if (currentIndex !== orders.length - 1) {
                    scrollToNextOrder()
                  }
                }}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </ArrowContainer>
          )}
        </HeaderContainer>
      )}
      <ActiveOrdersContainer singleorder={orders.length === 1}>
        <Carousel
          allowTouchMove={false}
          setSwiperInstance={setOrderSwiperInstance}
          slides={orders.map(({ node: order }, index) => {
            const formattedDeliveryDate = order.estimatedDeliveryDate
              ? new Date(order.estimatedDeliveryDate)
              : null

            const formattedCollectionDate = order.estimatedCompletionTime
              ? new Date(order.estimatedCompletionTime)
              : null
            return (
              <Order
                key={order.id}
                route={{
                  mainRouteName: MainRouteName.ORDERS,
                  pageDataId: order.id,
                }}
                firstOrder={orders.length > 1 && index === 0}
                lastOrder={index === orders.length - 1}
                active={
                  route?.mainRouteName === MainRouteName.ORDERS &&
                  route?.pageDataId === order.id
                }
              >
                <SingleActiveOrderContainer
                  oneActiveOrder={orders.length === 1}
                >
                  {(formattedCollectionDate ||
                    formattedDeliveryDate ||
                    order.narrowFulfilmentMethod ===
                      NarrowFulfilmentMethodInputType.TABLE) && (
                    <StatusBox
                      isAccountPage={isAccountPage}
                      myOrder={order}
                      fulfillmentMethod={order.narrowFulfilmentMethod}
                      startWindow={
                        order.estimatedCompletionTime
                          ? new Date(order.estimatedCompletionTime)
                          : undefined
                      }
                      endWindow={
                        order.estimatedDeliveryDate
                          ? new Date(order.estimatedDeliveryDate)
                          : undefined
                      }
                      formattedDeliveryDate={formattedDeliveryDate ?? undefined}
                      formattedCollectionDate={
                        formattedCollectionDate ?? undefined
                      }
                      createdAt={new Date(order.createdAt)}
                    />
                  )}
                </SingleActiveOrderContainer>
              </Order>
            )
          })}
        />
      </ActiveOrdersContainer>
    </>
  )
}
