import React from 'react'
import { useTranslation } from 'react-i18next'

import { FulfilmentWhenLabel } from '@src/components/FulfilmentWhenLabel/FulfilmentWhenLabel'
import { FulfilmentWhereLabel } from '@src/components/FulfilmentWhereLabel/FulfilmentWhereLabel'
import { DotSeparatedList } from '@src/components/DotSpan/DotSeparatedList'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useServiceComponentModal } from '@src/hooks/useServiceComponentModal/useServiceComponentModal'
import { BagDeliverySVG } from '@src/images/BagDelivery'

import {
  FulfilmentContainer,
  FulfilmentDetailsContainer,
  PaddedDotSpan,
} from './FulfilmentTabStyles'
import { StyledDoubleArrowSVG } from './OutletHeaderMobileStyles'
import { CurrentFulfilmentType } from '../../../hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'

export enum TabString {
  SERVICE = 'SERVICE',
  WHEN = 'WHEN',
  LOCATION = 'LOCATION',
  WHERE = 'WHERE',
}

export const HeaderTab: React.FC<{
  fulfilmentTabs: TabString[]
  permanentCheckout?: boolean
  dataTestId?: string
}> = ({ fulfilmentTabs, permanentCheckout = false, dataTestId }) => {
  const { openServiceComponentModal } = useServiceComponentModal()
  const { t } = useTranslation('outletHeader')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const currentFulfilmentType = outletFulfilment.data.currentFulfilment.type

  const selectedNarrowFulfilment =
    outletFulfilment.data.currentFulfilment.narrowType

  const shouldShowWhereLabel = () => {
    if (currentFulfilmentType === CurrentFulfilmentType.COLLECTION) return false

    if (
      currentFulfilmentType === CurrentFulfilmentType.TABLE_UNSELECTED &&
      outletFulfilment.outlet.isPendingTableNumbersEnabled
    )
      return false

    return true
  }

  const listItems = [
    fulfilmentTabs?.includes(TabString.SERVICE) &&
      t(selectedNarrowFulfilment.toLowerCase()),

    fulfilmentTabs?.includes(TabString.WHEN) && (
      <FulfilmentWhenLabel stateType={OutletFulfilmentStateType.GLOBAL} />
    ),

    (fulfilmentTabs?.includes(TabString.LOCATION) ||
      fulfilmentTabs?.includes(TabString.WHERE)) &&
      shouldShowWhereLabel() && (
        <FulfilmentWhereLabel stateType={OutletFulfilmentStateType.GLOBAL} />
      ),
  ]

  const handleClick = () => {
    openServiceComponentModal()
  }

  return (
    <FulfilmentContainer onClick={handleClick} data-test-id={dataTestId}>
      <BagDeliverySVG id="service" />
      <FulfilmentDetailsContainer permanentCheckout={permanentCheckout}>
        <DotSeparatedList dotSpan={<PaddedDotSpan>{'•'}</PaddedDotSpan>}>
          {listItems}
        </DotSeparatedList>
      </FulfilmentDetailsContainer>
      <StyledDoubleArrowSVG id="fulfilmentArrow" />
    </FulfilmentContainer>
  )
}
