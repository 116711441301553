import { Reference, useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import React from 'react'

import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { SharedCustomerDetails } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'

import { Address } from './Address/Address'
import { AddressBookUpdateAddressDocument } from './mutations/__generated__/AddressBookUpdateAddress.graphql-interface'

export const AddressList: React.FC<{
  customerDetails: SharedCustomerDetails
}> = ({ customerDetails }) => {
  const { deliveryAddresses } = customerDetails
  const [updateAddress, { loading }] = useMutation(
    AddressBookUpdateAddressDocument,
    {
      update(cache, result) {
        // Updates all the existing deliveryAddresses in the cache to no longer be default after we set a new one.
        const defaultAddressId = result.data?.editAddress.address.id
        if (defaultAddressId) {
          cache.modify({
            id: cache.identify({ ...customerDetails }),
            fields: {
              deliveryAddresses(
                existing: readonly Record<string, any>[] | Reference = []
              ) {
                if (!Array.isArray(existing)) return existing

                existing.forEach(addressRef => {
                  cache.modify({
                    id: cache.identify(addressRef),
                    fields: {
                      default(_existing, { readField }) {
                        const id = readField('id', addressRef)
                        return id === defaultAddressId
                      },
                    },
                  })
                })
                return existing
              },
            },
          })
        }
      },
    }
  )

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={{
        defaultAddressId: deliveryAddresses.find(
          deliveryAddress => deliveryAddress.default
        )?.id,
      }}
      onSubmit={values => {
        const { defaultAddressId } = values
        if (defaultAddressId) {
          void updateAddress({
            variables: {
              input: { id: defaultAddressId, default: true },
            },
          })
        }
      }}
    >
      {({ handleSubmit }) => (
        <Form
          onChange={function () {
            handleSubmit()
          }}
        >
          {deliveryAddresses.map((deliveryAddress, index) => (
            <Address
              key={deliveryAddress.id}
              index={index}
              address={deliveryAddress}
            />
          ))}
        </Form>
      )}
    </Formik>
  )
}
