import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'

import {
  RecentButton,
  Year,
  YearNumber,
  YearsContainer,
  YearsStyledSwiper,
} from './YearsPicker.styles'

import { CaretLeft } from '../SVGS/CaretLeft'
import { CaretRight } from '../SVGS/CaretRight'

export const YearsPicker: React.VFC<{
  setSelectedYear: (value: number) => void
  selectedYear: number | null
  registeredAt: string
}> = ({ setSelectedYear, selectedYear, registeredAt }) => {
  const { t } = useTranslation('yearsPicker')

  const years = useMemo(() => {
    const getYearsFromRegisteredDate = () => {
      const years = []
      const currentYear = new Date().getFullYear()
      for (
        let i = currentYear;
        i >= new Date(registeredAt).getFullYear();
        i--
      ) {
        years.push(i)
      }
      return years
    }
    return getYearsFromRegisteredDate()
  }, [registeredAt])

  return (
    <YearsContainer>
      <YearsStyledSwiper
        modules={[FreeMode, Navigation]}
        freeMode={true}
        className="mySwiper"
        slidesPerView={'auto'}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        slideVisibleClass="swiper-slide-visible"
      >
        {years.map(year => {
          return (
            <>
              {year === new Date().getFullYear() ? (
                <SwiperSlide
                  className="recent-slide"
                  key={`yearSlide${format(year, 'yyyy')}`}
                >
                  <RecentButton
                    selected={new Date().getFullYear() === selectedYear}
                    onClick={() => setSelectedYear(new Date().getFullYear())}
                  >
                    {t('recent')}
                  </RecentButton>
                </SwiperSlide>
              ) : (
                <SwiperSlide key={`yearSlide${year}`}>
                  <Year
                    onClick={() => setSelectedYear(year)}
                    selected={Boolean(selectedYear && year === selectedYear)}
                  >
                    <YearNumber
                      selected={Boolean(selectedYear && year === selectedYear)}
                    >
                      {year}
                    </YearNumber>
                  </Year>
                </SwiperSlide>
              )}
            </>
          )
        })}
        <div className="swiper-button-prev">
          <CaretLeft title="previous" id="previous" />
        </div>
        <div className="swiper-button-next">
          <CaretRight title="next" id="next" />
        </div>
      </YearsStyledSwiper>
    </YearsContainer>
  )
}
