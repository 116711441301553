import { Formik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { mapZodToFormikErrors } from '@src/utils/mapZodToFormikErrors'

import { DeliveryFulfilmentForm } from './DeliveryFulfilmentForm'
import { useDeliveryFulfilmentFormikProps } from './useDeliveryFulfilmentFormikProps/useDeliveryFulfilmentFormikProps'

// Component which renders the loading state while the formik props and addresses
// are loaded
export const DeliveryFulfilmentStep: React.FC = () => {
  const formikProps = useDeliveryFulfilmentFormikProps()
  const validate = useCallback(
    async values => {
      const parsed = await formikProps.zodValidationSchema.safeParseAsync(
        values
      )
      if (!parsed.success) {
        return mapZodToFormikErrors(parsed.error)
      }
    },
    [formikProps.zodValidationSchema]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedInitialValues = useMemo(() => formikProps.initialValues, [])

  return (
    <Formik
      enableReinitialize={false}
      initialValues={memoizedInitialValues}
      onSubmit={formikProps.handleSubmit}
      validate={validate}
      component={DeliveryFulfilmentForm}
    />
  )
}
