import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

import { AlertOkModal } from '@src/App/AlertOkModal'
import { AcceptDiscountModal } from '@src/components/AcceptDiscountModal/AcceptDiscountModal'
import { BurgerMenu } from '@src/components/BurgerMenu/BurgerMenu'
import { Footer } from '@src/components/Footer/Footer'
import { Modal, ModalPosition } from '@src/components/Modal/Modal'
import { MobileOutletServicePopover } from '@src/components/SiteHeader/ServiceNavigation/Popovers/MobileOutletServicePopover'
import { Tooltip } from '@src/components/Tooltip/Tooltip'
import { breakpoints } from '@src/constants/breakpoints'
import { DefaultLocationOption, LocationType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFindLocation } from '@src/hooks/useFindLocation'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSecondaryNavigation } from '@src/hooks/useSecondaryNavigation'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  CheckoutContainer,
  MainContent,
  Container,
  ContentWrapper,
} from './PageWrapper.styles'

import { Checkout } from '../components/CheckoutModal/Checkout/Checkout'
import { DirtyBasketAlert } from '../components/DirtyBasket/DirtyBasketAlert'
import { Helmet } from '../components/Helmet'
import { SiteHeader } from '../components/SiteHeader/SiteHeader'
import { BasketOutletProvider } from '../hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/context/BasketOutletProvider'
import { useFulfilmentFilter } from '../hooks/useFulfilmentFilter/useFulfilmentFilter'
import { jwtVar } from '../models/customer/jwt'

export const PageWrapper: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { navigateToMainContent } = useSecondaryNavigation()
  const checkoutRouter = useCheckoutRouter()
  const [queryParams] = useQueryParams({
    enrolmentKey: StringParam,
  })
  const [isOpen, setIsOpen] = useState(true)
  const isLoggedIn = useReactiveVar(jwtVar)
  const {
    data: { where: fulfilmentFilterWhere },
    setLocationType,
  } = useFulfilmentFilter()
  const screenResolution = useReactiveVar(screenResolutionVar)
  const basketItems = useBasketItems()
  const marketplace = useMarketplace()

  const findLocation = useFindLocation({
    onSuccess: coordinates => {
      setLocationType({
        type: LocationType.COORDINATES,
        coordinates,
      })
    },
  })

  const hasBasketItems = basketItems.items.length > 0
  const { displayPermanentCheckout } = useCheckoutRouter()

  useEffect(() => {
    if (fulfilmentFilterWhere.location.type === LocationType.EVERYWHERE) {
      if (!isLoggedIn) {
        if (
          marketplace.defaultLocationOption === DefaultLocationOption.NEARME
        ) {
          findLocation()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const expectedPathPattern = `/${marketplace.urlPath}/:outletId/:restaurantName/menu`

  const OutletMenuView = useMatch(expectedPathPattern)

  return (
    <>
      <AlertOkModal />
      <Tooltip id="page-wrapper-tooltip" />
      <Modal
        position={ModalPosition.CENTER}
        isOpen={!!queryParams.enrolmentKey && isOpen}
        closeType={{
          type: 'cross' as const,
          handleClose: navigateToMainContent,
        }}
        height={400}
      >
        <AcceptDiscountModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </Modal>
      {hasBasketItems && (
        <BasketOutletProvider>
          <MobileOutletServicePopover />
          <DirtyBasketAlert />
        </BasketOutletProvider>
      )}
      <BurgerMenu />
      <Container>
        <ContentWrapper>
          <MainContent isOutletMenuView={!!OutletMenuView}>
            <SiteHeader />
            {children}
          </MainContent>
          <Footer />
        </ContentWrapper>

        {screenResolution.width >= breakpoints.wideDesktop ? (
          displayPermanentCheckout && (
            <CheckoutContainer>
              <Checkout />
            </CheckoutContainer>
          )
        ) : (
          <Modal
            isOpen={checkoutRouter.route !== undefined}
            position={ModalPosition.RIGHT}
            closeType={{
              type: 'clickOutsideOnly' as const,
              handleClose: () => {
                checkoutRouter.reset()
              },
            }}
          >
            {
              // update the metadata when the user opens the checkout modal
              checkoutRouter.route === CheckoutRoute.BASKET && (
                <Helmet title="Checkout" />
              )
            }
            <CheckoutContainer>
              <Checkout />
            </CheckoutContainer>
          </Modal>
        )}
      </Container>
    </>
  )
}
