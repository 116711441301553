import { useReactiveVar } from '@apollo/client'
import { ExpressCheckoutElement } from '@stripe/react-stripe-js'
import { endOfMonth, isAfter } from 'date-fns'
import { Formik, FormikErrors } from 'formik'
import { toUpper } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { basketItemsToOrderItems } from '@src/components/CheckoutModal/Checkout/utils/basketItemsToOrderItems'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import {
  CheckoutButton,
  FlexGrowForm,
  FlexGrowScrollContainer,
} from '@src/components/CheckoutModal/FormElements.styles'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { StripeElementsProvider } from '@src/components/Stripe/StripeElementsWrapper'
import { Totals } from '@src/components/Totals/Totals'
import {
  CardPartial,
  DetailedPaymentMethod,
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { ExtendedCurrentFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/extendData/types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import {
  SharedAddress,
  useCustomerDetailsAndAddressesQuery,
} from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { usePaymentCardsQuery } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'
import {
  DetailedPaymentMethodWithCard,
  paymentMethodVar,
} from '@src/hooks/useTotals/utils/paymentMethodReactiveVar'

import { getCreateOrderFulfilmentInput } from './getCreateOrderFulfilmentInput'
import { PaymentMethods } from './PaymentMethods/PaymentMethods'

import { ConfirmAddressModal } from '../ConfirmAddressPopover/ConfirmAddress.modal'
import { useCreateOrderMutation } from '../mutations/useCreateOrderMutation/useCreateOrderMutation'
import { OutletClosedAlert } from '../OutletClosedAlert/OutletClosedAlert'
const getDefaultPaymentMethod = (
  paymentCards: CardPartial[],
  isExpired: (card: CardPartial) => boolean,
  currentFulfilment: ExtendedCurrentFulfilment
): DetailedPaymentMethodWithCard => {
  // if its payment card, get the first card
  if (paymentCards && paymentCards.length > 0) {
    const validCards = paymentCards.filter(card => !isExpired(card))

    if (
      currentFulfilment.availablePaymentMethods.length === 1 &&
      currentFulfilment.availablePaymentMethods[0] ===
        DetailedPaymentMethod.CASH
    ) {
      return {
        detailedPaymentMethod: DetailedPaymentMethod.CASH,
      }
    }

    if (validCards && validCards.length > 0 && validCards[0] !== undefined) {
      return {
        detailedPaymentMethod: DetailedPaymentMethod.CARD,
        cardPaymentToken: validCards[0].id,
      }
    }
  }

  return {
    detailedPaymentMethod: DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
  }
}

//  Component which renders the loading state while the formik props and cards
// are loaded, and wrap the form in the Stripe Provider
export const PaymentStep: React.FC = () => {
  const basketTotals = useBasketTotals()

  if (
    !basketTotals.data ||
    basketTotals.loading ||
    !basketTotals.data.checkoutBasketTotals
  ) {
    return <LoadingSpinner />
  }
  return (
    <StripeElementsProvider
      amount={basketTotals.data.checkoutBasketTotals.sumTotal}
    >
      {({ isApplePayAvailable, isGooglePayAvailable }) => {
        return (
          <PaymentForm
            isApplePayAvailable={isApplePayAvailable}
            isGooglePayAvailable={isGooglePayAvailable}
          />
        )
      }}
    </StripeElementsProvider>
  )
}
const PaymentForm: React.FC<{
  isApplePayAvailable: boolean
  isGooglePayAvailable: boolean
}> = ({ isApplePayAvailable, isGooglePayAvailable }) => {
  const [showConfirmAddress, setConfirmAddress] = useState<
    SharedAddress | undefined
  >()
  const basketTotals = useBasketTotals()
  const basketItems = useBasketItems()
  const { confirmAddressAtPayment } = useMarketplace()
  const addressHasBeenConfirmed = useRef(false)
  const paymentMethod = useReactiveVar(paymentMethodVar)
  const { createOrder, validationErrors, loading } = useCreateOrderMutation()
  const {
    data: { currentFulfilment, historicalData },
    outlet,
  } = useOutletFulfilment({ stateType: OutletFulfilmentStateType.GLOBAL })
  const { data } = useCustomerDetailsAndAddressesQuery({
    addressAcceptingOrdersToOutletId: outlet.id,
  })
  const { t } = useTranslation('checkout')
  // Fetch payment cards
  const {
    data: paymentCardData,
    loading: loadingPaymentCards,
    error: paymentCardsDataError,
    refetch: refetchPaymentCardData,
  } = usePaymentCardsQuery({
    useQueryArgs: {
      onCompleted: _data => {
        if (
          paymentMethod.detailedPaymentMethod ===
          DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
        ) {
          const defaultedPaymentMethod = getDefaultPaymentMethod(
            paymentCardData?.customerDetails.cards.cards || [],
            isExpired,
            currentFulfilment
          )
          paymentMethodVar(defaultedPaymentMethod)
        }
      },
    },
  })

  const paymentCards = paymentCardData?.customerDetails.cards.cards || []

  const addresses = data?.customerDetails.deliveryAddresses || []
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()

  const isExpired = useCallback(
    (card: {
      id: string
      last4: string
      exp_year: string | null
      exp_month: string | null
      brand: string | null
    }) => {
      const cardExpiryDate = new Date(
        Number(card.exp_year),
        Number(card.exp_month) - 1
      )
      const cardExpiryEndOfMonth = endOfMonth(cardExpiryDate)
      return isAfter(new Date(), cardExpiryEndOfMonth)
    },
    []
  )

  if (loadingPaymentCards) {
    return <LoadingSpinner />
  }

  // will never happen as basket totals will already have data from previous steps
  if (!basketTotals.data?.checkoutBasketTotals) {
    return null
  }

  const amount = basketTotals.data?.checkoutBasketTotals.sumTotal

  const isAgeVerificationRequired =
    basketTotals.data.basketItemsWithPrices.some(
      item => item.outletMenuItem.ageRestricted
    )

  if (!currentFulfilment.availablePaymentMethods.length) {
    const fulfilmentErrorMessageMap: Record<
      NarrowFulfilmentMethodInputType,
      string
    > = {
      [NarrowFulfilmentMethodInputType.DELIVERY]: t(
        'no_payment_methods_delivery'
      ),
      [NarrowFulfilmentMethodInputType.COLLECTION]: t(
        'no_payment_methods_collection'
      ),
      [NarrowFulfilmentMethodInputType.TABLE]: t('no_payment_methods_table'),
    }
    return (
      <ErrorPage
        errorMessage={fulfilmentErrorMessageMap[currentFulfilment.narrowType]}
      />
    )
  }

  const fulfilmentInput = getCreateOrderFulfilmentInput({ currentFulfilment })
  if (!fulfilmentInput) {
    return (
      <ErrorPage
        errorMessage={t('fulfilment_unavailable', {
          narrowFulfilmentMethod: t(currentFulfilment.narrowType.toLowerCase()),
        })}
      />
    )
  }

  const initialValues = {
    addressConfirmed: showConfirmAddress,
    ageVerificationConfirmed: !isAgeVerificationRequired,
    payment: paymentMethod,
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validate={values => {
          const errors: FormikErrors<typeof initialValues> = {}
          if (isAgeVerificationRequired && !values.ageVerificationConfirmed) {
            errors.ageVerificationConfirmed = t('age_verification_error')
          }
          if (
            confirmAddressAtPayment &&
            !addressHasBeenConfirmed.current &&
            currentFulfilment.location.type === LocationType.ADDRESS &&
            addresses.length > 1
          ) {
            const addressId = currentFulfilment.location.addressId
            const selectedAddress = addresses.find(
              (a: { id: string }) => a.id === addressId
            )
            if (selectedAddress) {
              errors.addressConfirmed = t('address_verification_error')
              setConfirmAddress(selectedAddress)
            }
          }
          return errors
        }}
        onSubmit={_values => {
          void createOrder({
            orderData: {
              fulfilment: fulfilmentInput,
              payment: {
                paymentMethod: paymentMethod.detailedPaymentMethod,
                ...(paymentMethod.detailedPaymentMethod ===
                  DetailedPaymentMethod.CARD && {
                  cardPaymentToken: paymentMethod.cardPaymentToken,
                }),
                ...(paymentMethod.detailedPaymentMethod ===
                  DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD && {
                  saveCard: true,
                }),
              },
              orderItems: basketItemsToOrderItems(basketItems.items),
              customerOrderNotes: historicalData.orderNotes,
              discountId:
                basketTotals.data?.checkoutBasketTotals?.appliedDiscount
                  ?.discount?.id,
              voucherKey:
                basketTotals.data?.checkoutBasketTotals?.appliedDiscount
                  ?.discount?.voucher,
            },
          })
        }}
      >
        {({ submitForm, validateForm }) => {
          const paymentInfo = {
            availablePaymentMethods: currentFulfilment.availablePaymentMethods,
            paymentValue: {
              paymentMethod: paymentMethod.detailedPaymentMethod,
              ...(paymentMethod.detailedPaymentMethod ===
                DetailedPaymentMethod.CARD && {
                cardPaymentToken: paymentMethod.cardPaymentToken,
              }),
            },
            amount,
          }
          const paymentPlatforms = {
            isApplePayAvailable,
            isGooglePayAvailable,
          }
          const addressId = historicalData.addressId || undefined
          const tableId = historicalData.tableId || undefined
          const paymentCardsInfo = {
            loadingPaymentCards,
            paymentCards,
            isExpired,
            refetchPaymentCardData,
            paymentCardsDataError,
          }
          return (
            <FlexGrowForm
              $windowHeight={window.innerHeight}
              $hasSafeArea={safeAreaInsetBottom > 0}
            >
              <FlexGrowScrollContainer>
                <PaymentMethods
                  paymentInfo={paymentInfo}
                  verificationInfo={{ isAgeVerificationRequired }}
                  historicalData={{ addressId, tableId }}
                  paymentPlatforms={paymentPlatforms}
                  paymentCardsInfo={paymentCardsInfo}
                  loading={loading}
                />
              </FlexGrowScrollContainer>
              <CheckoutButtonWrapperMobile>
                {!isMobile && safeAreaInsetBottom === 0 && <Totals />}
                {paymentMethod.detailedPaymentMethod ==
                DetailedPaymentMethod.WALLET ? (
                  <ExpressCheckoutElement
                    options={{
                      buttonHeight: 55,
                      layout: { maxColumns: 1, maxRows: 1 },
                      paymentMethodOrder: ['apple-pay', 'google-pay'],
                    }}
                    onClick={async event => {
                      await validateForm().then(errors => {
                        if (Object.keys(errors).length === 0) {
                          event.resolve()
                        }
                      })
                    }}
                    onConfirm={submitForm}
                  />
                ) : (
                  <CheckoutButton
                    content={toUpper(t('pay_now'))}
                    type="submit"
                    loading={loading}
                    dataTestId="pay-now-button"
                  />
                )}
              </CheckoutButtonWrapperMobile>
              <ConfirmAddressModal
                isOpen={Boolean(showConfirmAddress)}
                address={showConfirmAddress}
                onClose={() => setConfirmAddress(undefined)}
                addressConfirmed={async () => {
                  addressHasBeenConfirmed.current = true
                  setConfirmAddress(undefined)
                  await submitForm()
                }}
              />
            </FlexGrowForm>
          )
        }}
      </Formik>
      <OutletClosedAlert message={validationErrors.outletUnavailableMessage} />{' '}
    </>
  )
}
