import { useReactiveVar } from '@apollo/client'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBreakpoint } from '@src/hooks/useBreakpoint'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { jwtVar } from '@src/models/customer/jwt'

import { DrawerHeader, DrawerHeaderActions } from '../Drawer/DrawerHeader'

export const CheckoutHeader: React.FC<{
  manualLoadingState: boolean
}> = ({ manualLoadingState }) => {
  const { t } = useTranslation('checkout')
  const { defaultedRoute } = useCheckoutRouter()
  const { isWideDesktop } = useBreakpoint()
  const isAuthenticated = useReactiveVar(jwtVar) !== null

  const headerTitle = useMemo(() => {
    if (manualLoadingState) return ' ' // Needed to prevent the header return button from bouncing to the left briefly

    switch (defaultedRoute) {
      case CheckoutRoute.BASKET:
        return t('basket')
      case CheckoutRoute.FULFILMENT:
        return t('checkout')
      case CheckoutRoute.PAYMENT:
        return t('payment')
      case CheckoutRoute.OFFERS:
        return t('offers_for_you')
      case CheckoutRoute.PAYMENT_REDIRECT_VERIFICATION:
        return t('payment')
      case CheckoutRoute.COMPLETE:
        return t('your_order')
    }
  }, [defaultedRoute, t, manualLoadingState])

  const action = useMemo(() => {
    if (defaultedRoute === CheckoutRoute.BASKET) {
      // wide desktop basket has no back button, everywhere else is close
      return isWideDesktop ? undefined : DrawerHeaderActions.CLOSE
    }

    // complete has a close button, everywhere else is back
    return defaultedRoute === CheckoutRoute.COMPLETE
      ? DrawerHeaderActions.CLOSE
      : DrawerHeaderActions.BACK
  }, [defaultedRoute, isWideDesktop])

  // unauthenticated fulfilment step has it's own header
  if (defaultedRoute === CheckoutRoute.FULFILMENT && !isAuthenticated) {
    return null
  }

  return <DrawerHeader headerText={headerTitle} action={action} isCheckout />
}
