import { useQuery, useReactiveVar } from '@apollo/client'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType } from '@src/components/Button/Button'
import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { Helmet } from '@src/components/Helmet'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { CardNumber } from '@src/components/PaymentCard/PaymentCard'
import { StripeElementsProvider } from '@src/components/Stripe/StripeElementsWrapper'
import { AmexSVG } from '@src/components/SVGS/AmexSVG'
import { CvCSVG } from '@src/components/SVGS/CvCSVG'
import { MastercardSVG } from '@src/components/SVGS/MastercardSVG'
import { VisaSVG } from '@src/components/SVGS/VisaSVG'
import { breakpoints } from '@src/constants/breakpoints'
import { usePaymentCardsQuery } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'
import { screenResolutionVar } from '@src/models/screenResolution'

import { PaymentCards } from './PaymentCards'
import {
  CardInput,
  CardInputSmall,
  CardOuter,
  CardsContainer,
  CARD_ELEMENT_STYLE,
  CVCContainer,
  CVC_STYLE,
  EXPIRY_STYLE,
  HeaderContainer,
  InputContainer,
  LoaderOverlay,
  NewCardContainer,
  SmallFont,
} from './PaymentDetailsStyles'
import { getCardStripeSecretIdDocument } from './queries/__generated__/getClientSecret.graphql-interface'

export const PaymentDetails = () => (
  // TODO: This should use a provider which doesn't require the amount
  <StripeElementsProvider amount={100}>
    {() => <PaymentDetailsInner />}
  </StripeElementsProvider>
)

const PaymentDetailsInner = () => {
  useNotLoggedInRedirect()
  const { t } = useTranslation('paymentDetails')
  const elements = useElements()

  const stripe = useStripe()
  // does using this hook here and in the child PaymentCards component cause multiple queries?
  const { refetch } = usePaymentCardsQuery()

  const [isAddingCard, setIsAddingCard] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const screenResolution = useReactiveVar(screenResolutionVar)
  const isWiderThanPhablet = screenResolution.width >= breakpoints.phablet

  const { data, loading, error } = useQuery(getCardStripeSecretIdDocument)

  if (!elements || !stripe || loading) return <LoadingSpinner />
  if (error || !data) {
    setShowErrorModal(true)
    return null
  }

  const { getCardClientSecret } = data

  const handleAddNewCard = async () => {
    // getElement for one form element gets all associated elements
    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      setShowErrorModal(true)
      return false
    }

    // Confirm card setup using Stripe.js
    const { error, setupIntent } = await stripe.confirmCardSetup(
      getCardClientSecret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    )

    if (error || !setupIntent || setupIntent.status !== 'succeeded') {
      setShowErrorModal(true)
      return false
    }

    const cvcElement = elements.getElement(CardCvcElement)
    const expiryDates = elements.getElement(CardExpiryElement)

    cvcElement?.clear()
    expiryDates?.clear()
    cardElement.clear()

    void refetch()
  }

  return (
    <>
      <Helmet title={t('saved_cards')} />
      <BurgerSection header={{ title: t('saved_cards') }}>
        <PaymentCards />
        <NewCardContainer>
          <HeaderContainer>
            <CardNumber>{t('add_card')}</CardNumber>
            <CardsContainer>
              <AmexSVG id="amex" />
              <VisaSVG id="visa" />
              <MastercardSVG id="mastercard" />
            </CardsContainer>
          </HeaderContainer>
          <>
            {isAddingCard && (
              <LoaderOverlay>
                <LoadingSpinner text={t('please_wait')} />
              </LoaderOverlay>
            )}
            <CardOuter disabled={isAddingCard}>
              <>
                <CardInput data-test-id="add-card-number-input">
                  <CardNumberElement options={CARD_ELEMENT_STYLE} />
                </CardInput>
                <CardInputSmall data-test-id="add-card-expiry-input">
                  <CardExpiryElement options={EXPIRY_STYLE} />
                </CardInputSmall>
                {/* Do not render from this width, otherwise styling is thrown as css visibility props still render the components */}
                {isWiderThanPhablet ? (
                  <>
                    <InputContainer>
                      <CardInputSmall data-test-id="add-card-cvc-input">
                        <CardCvcElement options={CVC_STYLE} />
                      </CardInputSmall>
                      <SmallFont>
                        <strong>{t('security_code')}</strong> {'-'}{' '}
                        {t('security_desc')}
                      </SmallFont>
                    </InputContainer>
                    <CVCContainer>
                      <CvCSVG id="cvc" />
                    </CVCContainer>
                  </>
                ) : (
                  <>
                    <CardInputSmall>
                      <CardCvcElement options={CVC_STYLE} />
                    </CardInputSmall>
                  </>
                )}
              </>

              <Button
                type="button"
                onClick={async () => {
                  setIsAddingCard(true)
                  await handleAddNewCard()
                  setIsAddingCard(false)
                }}
                content={t('add_card')}
                dataTestId="add-new-card-submit"
              />
            </CardOuter>
          </>
        </NewCardContainer>
        {/* TODO: RED-4963 default cards (post-MVP)  */}
      </BurgerSection>
      <AlertModal
        isOpen={showErrorModal}
        title={t('card_issue')}
        subTitle={t('card_add_error')}
        action={{
          text: t('ok'),
          intent: ButtonType.DANGER,
          onClick: () => setShowErrorModal(false),
        }}
      />
    </>
  )
}
