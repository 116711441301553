import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import omit from 'lodash/omit'
import React from 'react'
import { useTranslation, TFunction } from 'react-i18next'

import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Helmet } from '@src/components/Helmet'
import { CheckboxInput } from '@src/components/Inputs'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { Customer } from '@src/graphql-types'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'

import {
  Container,
  SectionDescription,
  SectionContainer,
  SectionTitle,
  UpdateButton,
} from './MarketingPrefernces.styles'
import { UpdateMarketingPreferencesMutationDocument } from './mutations/__generated__/UpdateMarketingPreferencesMutation.graphql-interface'

type MarketingPreferencesValues = Pick<
  Customer,
  | 'optOutNewRestaurantMarketing'
  | 'optOutMenuMarketing'
  | 'optOutPromotionMarketing'
  | 'optOutMarketingSMS'
  | 'optOutMarketingEmail'
  | 'optOutMarketingPush'
>

// Values are inverted initially so the user sees it as "opted in" to as its more natural.
// On submit we invert them back as the server stores as "opted out".
const invertValues = (
  values: MarketingPreferencesValues
): MarketingPreferencesValues => ({
  optOutNewRestaurantMarketing: !values.optOutNewRestaurantMarketing,
  optOutMenuMarketing: !values.optOutMenuMarketing,
  optOutPromotionMarketing: !values.optOutPromotionMarketing,
  optOutMarketingSMS: !values.optOutMarketingSMS,
  optOutMarketingEmail: !values.optOutMarketingEmail,
  optOutMarketingPush: !values.optOutMarketingPush,
})

const Content: React.FC<{
  t: TFunction<'marketing_preferences'>
}> = ({ t }) => {
  const { data, loading, error } = useCustomerDetailsAndAddressesQuery()

  const [
    updateMarketingPreferences,
    { loading: updating, error: errorUpdating },
  ] = useMutation(UpdateMarketingPreferencesMutationDocument)

  if (loading) {
    return <LoadingSpinner />
  }

  if (!data || error) {
    return <ErrorPage errorMessage={error?.message} logError={error} />
  }

  if (errorUpdating) {
    return (
      <ErrorPage
        errorMessage={errorUpdating?.message}
        logError={errorUpdating}
      />
    )
  }

  return (
    <Formik
      initialValues={invertValues(
        omit(data.customerDetails, ['id', '__typename'])
      )}
      onSubmit={async values => {
        await updateMarketingPreferences({ variables: invertValues(values) })
      }}
    >
      {({ dirty, handleSubmit, resetForm, values }) => (
        <Form>
          <Container>
            <SectionContainer>
              <SectionTitle>{t('messages_title')}</SectionTitle>
              <SectionDescription>
                {t('messages_description')}
              </SectionDescription>
              <CheckboxInput
                name="optOutNewRestaurantMarketing"
                label={t('optOutNewRestaurantMarketing.label')}
                labelOnLeft
                disabled={updating}
                last
              />
              <CheckboxInput
                name="optOutMenuMarketing"
                label={t('optOutMenuMarketing.label')}
                labelOnLeft
                disabled={updating}
                last
              />
              <CheckboxInput
                name="optOutPromotionMarketing"
                label={t('optOutPromotionMarketing.label')}
                labelOnLeft
                disabled={updating}
                last
              />
            </SectionContainer>

            <SectionContainer>
              <SectionTitle>{t('channels_title')}</SectionTitle>
              <SectionDescription>
                {t('channels_description')}
              </SectionDescription>
              <CheckboxInput
                name="optOutMarketingEmail"
                label={t('optOutMarketingEmail.label')}
                labelOnLeft
                disabled={updating}
                last
              />
              <CheckboxInput
                name="optOutMarketingPush"
                label={t('optOutMarketingPush.label')}
                labelOnLeft
                disabled={updating}
                last
              />
              <CheckboxInput
                name="optOutMarketingSMS"
                label={t('optOutMarketingSMS.label')}
                labelOnLeft
                disabled={updating}
                last
              />
            </SectionContainer>

            <UpdateButton
              content={t('update')}
              loading={updating}
              type="submit"
              onClick={e => {
                e?.preventDefault()
                handleSubmit()
                resetForm({ values })
              }}
              disabled={!dirty}
            />
          </Container>
        </Form>
      )}
    </Formik>
  )
}

export const MarketingPreferences = (): JSX.Element => {
  useNotLoggedInRedirect()
  const { t } = useTranslation('marketing_preferences')

  return (
    <>
      <Helmet title={t('title')} />
      <BurgerSection header={{ title: t('title') }}>
        <Content t={t} />
      </BurgerSection>
    </>
  )
}
