import { FormikProps } from 'formik'
import { toUpper } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddressBookButton } from '@src/components/CheckoutModal/Checkout/DeliveryFulfilmentStep/AddressBookButton'
import { OrderSummary } from '@src/components/CheckoutModal/Checkout/OrderSummary/OrderSummary'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import {
  CheckoutButton,
  FlexGrowForm,
  FlexGrowScrollContainer,
  FulfilmentNoteContainer,
  FulfilmentSectionContainer,
  StepTitle,
  TitleAndErrorContainer,
  ErrorMessage,
} from '@src/components/CheckoutModal/FormElements.styles'
import { CheckoutLoyaltyCardSlider } from '@src/components/CustomerLoyaltyCard/CheckoutLoyaltyCardSlider'
import { UncontrolledTextInput } from '@src/components/Inputs/TextInput/TextInput'
import { Totals } from '@src/components/Totals/Totals'
import { LocationType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'

import { AddAddressSection } from './AddAddressSection'
import { InnerScrollContainer } from './DeliveryFulfilmentStep.styles'
import { ExistingAddressSection } from './ExistingAddressSection'
import {
  AddressType,
  DeliveryFulfilmentFormSchema,
  EMPTY_ADDRESS,
} from './useDeliveryFulfilmentFormikProps/schema'

export const DeliveryFulfilmentForm: React.FC<
  FormikProps<DeliveryFulfilmentFormSchema>
> = ({
  values,
  isSubmitting,
  handleChange,
  setFieldValue,
  initialValues,
  handleSubmit,
  errors,
}) => {
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()
  const marketplace = useMarketplace()
  const { t } = useTranslation('checkout')

  const containerRef = useRef<HTMLDivElement>(null)
  const [addressError, setAddressError] = useState(false)

  useEffect(() => {
    if (errors.addressId) {
      setAddressError(true)
    }
  }, [errors])

  useEffect(() => {
    if (containerRef && containerRef.current && addressError) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [addressError])

  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const fulfilmentFilter = useFulfilmentFilter()

  const { data, loading, error } = useCustomerDetailsAndAddressesQuery({
    addressAcceptingOrdersToOutletId: outlet.id,
  })

  const addresses = data?.customerDetails.deliveryAddresses || []
  const defaultAddress = addresses.find(address => address.default)

  useEffect(() => {
    if (
      (fulfilmentFilter.data.where.location.type === LocationType.POSTCODE ||
        fulfilmentFilter.data.where.location.type ===
          LocationType.COORDINATES) &&
      addresses.length &&
      defaultAddress
    ) {
      setFieldValue('addressId', defaultAddress?.id)
      setFieldValue('addressType', AddressType.EXISTING)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses])

  return (
    <FlexGrowForm
      $windowHeight={window.innerHeight}
      $hasSafeArea={safeAreaInsetBottom > 0}
    >
      <FlexGrowScrollContainer id="fulfilment-container">
        <InnerScrollContainer>
          <CheckoutLoyaltyCardSlider />
          <TitleAndErrorContainer
            addressError={addressError}
            ref={containerRef}
          >
            <StepTitle>
              {t('select_or_add_address')}{' '}
              <AddressBookButton>{t('address_book')}</AddressBookButton>
            </StepTitle>
            {addressError && <ErrorMessage>{t('address_error')}</ErrorMessage>}
          </TitleAndErrorContainer>

          <FulfilmentSectionContainer
            onChange={e => {
              // whenever the new address radio button is selected, clear the addressId
              // and whenever an existing address is selected, update the addressType to EXISTING
              if (e.target instanceof HTMLInputElement) {
                if (
                  e.target.name === 'addressType' &&
                  e.target.value === AddressType.NEW
                ) {
                  setFieldValue('addressId', '')
                  setFieldValue(
                    'addAddress',
                    initialValues.addressType === AddressType.NEW
                      ? initialValues.addAddress
                      : EMPTY_ADDRESS
                  )
                } else if (e.target.name === 'addressId') {
                  setFieldValue('addressType', AddressType.EXISTING)
                }
              }
              setAddressError(false)
            }}
          >
            <ExistingAddressSection
              data={data}
              loading={loading}
              error={error}
            />
            <AddAddressSection t={t} values={values} />
          </FulfilmentSectionContainer>

          {/* Delivery note */}
          <FulfilmentNoteContainer>
            <UncontrolledTextInput
              name="deliveryNotes"
              touched
              label={t('delivery_note')}
              value={values.deliveryNotes}
              placeholder={
                marketplace.deliveryNoteText ?? t('delivery_note_example')
              }
              required={false}
              onChange={handleChange}
            />
          </FulfilmentNoteContainer>
          <OrderSummary
            addressId={
              values.addressType === AddressType.EXISTING
                ? values.addressId
                : ''
            }
            newAddress={
              values.addressType === AddressType.NEW
                ? `${values.addAddress.firstLine} ${values.addAddress.secondLine}`
                : ''
            }
          />
          {(isMobile || safeAreaInsetBottom > 0) && (
            <Totals
              addressIdOverride={
                values.addressType === AddressType.EXISTING
                  ? values.addressId
                  : undefined
              }
            />
          )}
        </InnerScrollContainer>
      </FlexGrowScrollContainer>
      <CheckoutButtonWrapperMobile>
        {!isMobile && safeAreaInsetBottom === 0 && (
          <Totals
            addressIdOverride={
              values.addressType === AddressType.EXISTING
                ? values.addressId
                : undefined
            }
          />
        )}

        <CheckoutButton
          loading={isSubmitting}
          disabled={loading}
          type="button"
          content={toUpper(t('continue_to_payment'))}
          onClick={e => {
            e.preventDefault()
            handleSubmit()
          }}
        />
      </CheckoutButtonWrapperMobile>
    </FlexGrowForm>
  )
}
