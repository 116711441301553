import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { apolloClient } from '@src/apolloClient'
import { Button } from '@src/components/Button'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { breakpoints } from '@src/constants/breakpoints'
import { getMyOrdersHistoryDocument } from '@src/hooks/sharedQueries/orderHistoryQuery/queries/__generated__/getMyOrdersHistory.graphql-interface'
import { MainRouteName, useAccountRouter } from '@src/hooks/useAccountRouter'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  ButtonTextContainer,
  ConfirmationText,
  Container,
  EmailText,
  OrderNoText,
  StyledClockSvg,
  TimerContainer,
  TitleText,
} from './CheckoutSuccess.styles'
import { CheckoutSuccessDataDocument } from './queries/__generated__/CheckoutSuccessData.graphql-interface'

export const CheckoutSuccess: React.FC = () => {
  const checkoutRouter = useCheckoutRouter()
  const [timerInterval, setTimerInterval] = useState(10)
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>()
  const skip = checkoutRouter.route !== CheckoutRoute.COMPLETE
  const { data, loading } = useQuery(
    CheckoutSuccessDataDocument,
    skip
      ? {
          skip,
        }
      : {
          variables: {
            orderId: checkoutRouter.orderId,
          },
        }
  )
  // const orderDetails = orderDetailsVar()
  const { t } = useTranslation(['checkout'])
  const accountRouter = useAccountRouter()
  const { width } = useReactiveVar(screenResolutionVar)

  useEffect(() => {
    let intervalId: NodeJS.Timer
    if (width < breakpoints.tablet) {
      intervalId = setInterval(() => {
        setTimerInterval(prevInterval => prevInterval - 1)
      }, 1000)
    }

    if (timerInterval === 0) {
      checkoutRouter.reset()
      void apolloClient.refetchQueries({
        include: [getMyOrdersHistoryDocument],
      })
    }

    return () => clearInterval(intervalId)
  }, [checkoutRouter, width, timerInterval])

  if (loading || skip || !data) {
    return <LoadingSpinner />
  }

  // TODO for now we dont have to worry about multiple orders so just get first
  const { id: orderId, orderNumber, outlet } = data.order
  return (
    <Container
      onMouseLeave={() => {
        intervalId && clearInterval(intervalId)
        const interval = setInterval(() => {
          setTimerInterval(prevInterval => prevInterval - 1)
        }, 1000)
        setIntervalId(interval)
      }}
      onMouseEnter={() => {
        intervalId && clearInterval(intervalId)
      }}
    >
      <LoaderCheck />

      <TitleText data-test-id="order_confirmed">
        {t('order_confirmed')}
        <br />
        {outlet.displayName}
      </TitleText>
      <OrderNoText>
        {t('order_no', {
          orderNumber,
        })}
      </OrderNoText>

      <ConfirmationText>
        {t('confirmation_updates_sent_to')}{' '}
        <EmailText>{data.customerDetails.email}</EmailText>
      </ConfirmationText>

      <Button
        onClick={() => {
          accountRouter.setRoute({
            mainRouteName: MainRouteName.ORDERS,
            pageDataId: orderId,
          })
          checkoutRouter.reset()
          void apolloClient.refetchQueries({
            include: [getMyOrdersHistoryDocument],
          })
        }}
        content={
          <ButtonTextContainer data-test-id="track-order">
            <div>{t('track_order')}</div>
            <TimerContainer>
              <StyledClockSvg id={'clock'} />
              {timerInterval}
              {'s'}
            </TimerContainer>
          </ButtonTextContainer>
        }
      />
    </Container>
  )
}
