import { useMutation, useQuery } from '@apollo/client'
import { Form, Formik } from 'formik'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { TextInput, FormError } from '@src/components/Inputs'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSecondaryNavigation } from '@src/hooks/useSecondaryNavigation'
import { jwtVar } from '@src/models/customer/jwt'

import { registerDocument } from './mutations/__generated__/register.graphql-interface'
import type { RegisterModalMarketPlaceByCname_marketplaceByCname as Marketplace } from './queries/__generated__/RegisterModalMarketPlaceByCname'
import { RegisterModalMarketPlaceByCnameDocument } from './queries/__generated__/RegisterModalMarketPlaceByCname.graphql-interface'
import {
  Container,
  Header,
  InnerForm,
  InputTitle,
  LinkText,
  SubmitButton,
  TermsCheckboxContainer,
  TermsText,
  TopRow,
  CreateAccountButton,
  JoinNowLink,
  SignInLink,
  HeaderContainer,
} from './Register.styles'
import { NewUser, newUserSchema } from './validation/newUserStructure'

import { StyledArrowLeftSVG, StyledButton } from '../../Drawer/Drawer.styles'
import { DrawerHeader, DrawerHeaderActions } from '../../Drawer/DrawerHeader'

const initialValues: NewUser = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  password: '',
  passwordConfirm: '',
  email: '',
}

const RegisterForm: React.FC<{
  marketplace: Marketplace
  onSigningUp?: () => void
  onLogin?: () => void
  isCheckout?: boolean
  isDiscountModal?: boolean
}> = ({
  marketplace,
  isCheckout,
  onSigningUp,
  onLogin,
  isDiscountModal = false,
}) => {
  const { navigateToMainContent } = useSecondaryNavigation()
  const { t } = useTranslation('signUp')
  const { key } = marketplace
  const checkoutRouter = useCheckoutRouter()
  const { featureLogin, legalTerms, legalPrivacy } = useMarketplace()

  const [registerMutation, { loading, error }] = useMutation(registerDocument, {
    onCompleted(data) {
      const token = data.register.token
      if (token) {
        if (onSigningUp) {
          onSigningUp()
        }

        // track registration with facebook pixel
        ReactPixel.track('CompleteRegistration')

        jwtVar(token)
        if (!isCheckout && !isDiscountModal) navigateToMainContent()
      }
    },
  })

  return (
    <>
      {!isCheckout && !isDiscountModal && (
        <DrawerHeader
          action={DrawerHeaderActions.CLOSE}
          headerText={t('sign_up')}
        />
      )}

      <Container isCheckout={isCheckout}>
        {isCheckout && (
          <HeaderContainer>
            <Header>{t('sign_up')}</Header>
            <StyledButton onClick={checkoutRouter.prev}>
              <StyledArrowLeftSVG id="back_button" />
            </StyledButton>
          </HeaderContainer>
        )}

        {!isDiscountModal && (
          <TopRow isCheckout={isCheckout}>
            {isCheckout ? (
              <CreateAccountButton
                onClick={() => {
                  if (onLogin) {
                    onLogin()
                  }
                }}
              >
                {t('already_have_an_account')}{' '}
                <SignInLink>{t('sign_in')}</SignInLink>
              </CreateAccountButton>
            ) : (
              featureLogin && (
                <JoinNowLink
                  to="/?account=login"
                  passthroughQueryParams={false}
                >
                  {t('already_have_an_account')}{' '}
                  <SignInLink>{t('sign_in')}</SignInLink>
                </JoinNowLink>
              )
            )}
          </TopRow>
        )}

        {error && <FormError>{error.message}</FormError>}

        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(newUserSchema)}
          validateOnBlur={false}
          validateOnChange={false}
          validate={values => {
            // This also exists on the zod validation. But the zod refine will not run until after
            // the user has filled out all fields for the newUser object.
            // This check will run the same validation before the user fills everything out if they do it out of order.
            const { password, passwordConfirm } = values
            if (password && passwordConfirm && password !== passwordConfirm) {
              return {
                passwordConfirm: t(
                  'confirm_password.validation.passwords_do_not_match'
                ),
              }
            }
          }}
          onSubmit={newUser => {
            void registerMutation({
              variables: { ...newUser, marketplaceKey: key },
            })
          }}
        >
          <Form>
            <InnerForm>
              <TextInput
                name="firstName"
                label={t('first_name.label')}
                required
                disabled={loading}
                focusOnLoad
                dataTestId="register-first-name-input"
              />
              <TextInput
                name="lastName"
                label={t('last_name.label')}
                required
                disabled={loading}
                dataTestId="register-last-name-input"
              />
              <TextInput
                name="phoneNumber"
                label={t('phone_number.label')}
                required
                disabled={loading}
                dataTestId="register-phone-number-input"
              />
              <TextInput
                name="email"
                label={t('email_address.label')}
                required
                disabled={loading}
                dataTestId="register-email-address-input"
              />
              <InputTitle>{t('password.title')}</InputTitle>
              <TextInput
                name="password"
                type="password"
                label={t('password.label')}
                required
                disabled={loading}
                dataTestId="register-password-input"
              />
              <TextInput
                name="passwordConfirm"
                type="password"
                label={t('confirm_password.label')}
                required
                disabled={loading}
                dataTestId="register-password-confirm-input"
              />
              {(legalTerms || legalPrivacy) && (
                <TermsCheckboxContainer>
                  <TermsText>
                    {t('continue') + ' '}
                    {legalTerms && (
                      <>
                        <LinkText
                          to="/?account=terms&from=register"
                          passthroughQueryParams={false}
                        >
                          {t('terms_and_conditions')}
                        </LinkText>{' '}
                        {' ' + t('and') + ' '}
                      </>
                    )}
                    {legalPrivacy && (
                      <LinkText
                        to="/?account=privacy&from=register"
                        passthroughQueryParams={false}
                      >
                        {t('privacy_policy', { ns: 'copy' })}
                      </LinkText>
                    )}
                  </TermsText>
                </TermsCheckboxContainer>
              )}
              <SubmitButton
                type="submit"
                content={t('sign_up')}
                loading={loading}
                dataTestId="create-account-submit-button"
              />
            </InnerForm>
          </Form>
        </Formik>
      </Container>
    </>
  )
}

export const Register: React.FC<{
  isCheckout?: boolean
  onSigningUp?: () => void
  navigateToLogin?: () => void
  showHeader?: boolean
  isDiscountModal?: boolean
}> = ({ isCheckout, onSigningUp, navigateToLogin, isDiscountModal }) => {
  const { data, loading, error } = useQuery(
    RegisterModalMarketPlaceByCnameDocument,
    {
      variables: { cname: window.location.hostname },
    }
  )

  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorPage previousPage={true} logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }

  return (
    <RegisterForm
      isCheckout={isCheckout}
      onSigningUp={onSigningUp}
      onLogin={navigateToLogin}
      isDiscountModal={isDiscountModal}
      marketplace={data.marketplaceByCname}
    />
  )
}
