import React from 'react'
import { capitalize, lowerCase } from 'lodash'
import { useTranslation } from 'react-i18next'

import { OptionItem } from '@src/components/CheckoutModal/BasketSummary/BasketItems/BasketItem/OptionItem'
import { FulfilmentWhenLabel } from '@src/components/FulfilmentWhenLabel/FulfilmentWhenLabel'
import { FulfilmentWhereLabelSavedAddress } from '@src/components/FulfilmentWhereLabel/FulfilmentWhereLabelSavedAddress'
import { FulfilmentWhereLabelTable } from '@src/components/FulfilmentWhereLabel/FulfilmentWhereLabelTable'
import { DotSeparatedList } from '@src/components/DotSpan/DotSeparatedList'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'

import {
  Container,
  CouponContainer,
  DiscountNotice,
  FulfilmentContainer,
  Header,
  HiddenRow,
  LabelledCouponContainer,
  MainContainer,
  OptionItemContainer,
  Row,
  RowContainer,
  SpaceBetweenRow,
  StyledCouponContainer,
  StyledCouponSVG,
  SubHeader,
  Table,
  TableData,
  PaddedDotSpan,
} from './OrderSummary.styles'

export const OrderSummary: React.FC<{
  addressId?: string
  newAddress?: string
  tableId?: string
}> = ({ addressId = '', tableId = '', newAddress = '' }) => {
  const { t } = useTranslation('checkout')
  const { addOnItems } = useBasketItems()
  const formatCurrency = useFormatCurrency()
  const {
    data: {
      currentFulfilment: { narrowType: currentNarrowFulfilmentMethod },
    },
    outlet,
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { data: basketTotals } = useBasketTotals()

  if (!basketTotals) return null

  const { __typename, id, city, countryCode, ...address } = outlet.outletAddress

  const collectionAddress = Object.values(address)
    .filter(value => value && value !== '')
    .join(', ')

  return (
    <Container>
      <SpaceBetweenRow>
        <Header>{t('order_summary.order_summary')}</Header>
        <SubHeader>
          <FulfilmentContainer>
            <DotSeparatedList dotSpan={<PaddedDotSpan>{'•'}</PaddedDotSpan>}>
              {[
                currentNarrowFulfilmentMethod &&
                  capitalize(t(lowerCase(currentNarrowFulfilmentMethod))),

                currentNarrowFulfilmentMethod !==
                  NarrowFulfilmentMethodInputType.TABLE && (
                  <FulfilmentWhenLabel
                    stateType={OutletFulfilmentStateType.GLOBAL}
                  />
                ),

                currentNarrowFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.COLLECTION &&
                  collectionAddress,

                currentNarrowFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.DELIVERY && (
                  <FulfilmentWhereLabelSavedAddress
                    addressId={addressId}
                    newAddress={newAddress}
                  />
                ),

                currentNarrowFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.TABLE &&
                  !outlet.isPendingTableNumbersEnabled && (
                    <FulfilmentWhereLabelTable
                      tableId={tableId}
                      outletId={outlet.id}
                    />
                  ),
              ]}
            </DotSeparatedList>
          </FulfilmentContainer>
        </SubHeader>
      </SpaceBetweenRow>
      <MainContainer>
        <Table>
          <HiddenRow>
            <th>{t('order_summary.item')}</th>
            <th>{t('order_summary.cost')}</th>
          </HiddenRow>
          {basketTotals.basketItemsWithPrices.map(item => (
            <RowContainer key={item.basketItem.id}>
              <Row>
                <TableData>{`${item.basketItem.quantity}x ${item.outletMenuItem.name}`}</TableData>
                <TableData>
                  {item.isAddOnItem ? (
                    <CouponContainer>
                      <StyledCouponContainer>
                        <StyledCouponSVG
                          id="offer-item"
                          title={t('order_summary.offer_item')}
                          desc={t('order_summary.offer_item_desc')}
                        />
                      </StyledCouponContainer>
                      {/*  TODO show old price stikethrough here ?? */}
                      {formatCurrency(item.totalPrice)}
                    </CouponContainer>
                  ) : (
                    <>{formatCurrency(item.totalPrice)}</>
                  )}
                </TableData>
              </Row>
              {item.selectedOptionItems.length > 0 && (
                <OptionItemContainer>
                  {item.selectedOptionItems.map(optionItem => (
                    <OptionItem
                      optionItem={optionItem}
                      key={optionItem.id}
                      isOrderSummary
                      quantity={item.basketItem.quantity}
                    />
                  ))}
                </OptionItemContainer>
              )}
            </RowContainer>
          ))}
        </Table>
        {addOnItems.length > 0 &&
          basketTotals.checkoutBasketTotals?.appliedDiscount !== null && (
            <DiscountNotice>
              <LabelledCouponContainer>
                <StyledCouponSVG id="offer-item" />
              </LabelledCouponContainer>
              <p>{t('order_summary.offer_items_excluded')}</p>
            </DiscountNotice>
          )}
      </MainContainer>
    </Container>
  )
}
