import styled, { css } from 'styled-components'

import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

export const Container = styled.div`
  background-color: white;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;

  border-bottom: 1px solid rgba(149, 157, 165, 0.2);
  box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;
`

export const CapsuleContainer = styled.div`
  display: flex;
  padding: 8px 18px;
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;
  ${removeScrollbar};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 24px;
  }
`

export const FilterOverlay = styled.div`
  position: absolute;
  height: 52px;
  top: 11px;
  width: 52px;
  background-color: white;
  z-index: 1;
  right: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0)
  );
`

const baseCapsuleStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;
`

export const Capsule = styled.button<{ active: boolean }>`
  ${baseCapsuleStyles}
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  padding: ${({ active }) => (active ? `6px 12px` : `6px 2px`)};
  margin: 8px 16px 8px 0px;
  color: ${({ active, theme }) =>
    active ? theme.colors.brandDark : theme.colors.brand};
  background: ${({ active, theme }) =>
    active ? theme.colors.brandLight : 'transparent'};
  transform: ${({ active }) => (active ? 'scale(1.02)' : 'scale(1)')};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    height: 36px;
  }

  ${({ active, theme }) =>
    !active &&
    css`
      &:hover {
        color: ${theme.colors.brandLight};
      }
    `}
`

export const OutletMenuSearchContainer = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-right: 16px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    margin-right: ${basketWidth.superWideDesktop}px;
  }
`
