import { format, startOfDay, isSameDay } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Mousewheel } from 'swiper/modules'
import { Swiper as SwiperType } from 'swiper/types'

import {
  DaySelector,
  DaysSwiperContainer,
} from '@src/components/DatePicker/DatePicker.styles'
import { calendarString } from '@src/components/DatePicker/utils/dateString'

export const SwiperDaysPicker: React.FC<{
  setDaysSwiperInstance: React.Dispatch<React.SetStateAction<SwiperType | null>>
  preOrderDays: {
    date: string | Date
    full?: boolean
  }[]
  setSelectedDate: (dateSelected: Date) => void
  selectedDate?: Date | null
}> = ({
  setDaysSwiperInstance,
  preOrderDays,
  setSelectedDate,
  selectedDate,
}) => {
  const { t } = useTranslation('datePicker')

  return (
    <>
      <DaysSwiperContainer className="swiper-container">
        <Swiper
          modules={[Mousewheel, Keyboard]}
          spaceBetween={8}
          slidesPerView={'auto'}
          onSwiper={swiper => setDaysSwiperInstance(swiper)}
          mousewheel={true}
          keyboard={true}
          direction="horizontal"
        >
          {preOrderDays?.map((day, index) => (
            <SwiperSlide key={`preorderDay${index}`} role="presentation">
              <DaySelector
                onClick={() => {
                  setSelectedDate(startOfDay(new Date(day.date)))
                }}
                selected={
                  !!selectedDate && isSameDay(selectedDate, new Date(day.date))
                }
              >
                <div>{t(calendarString(day.date))}</div>
                <div>{format(new Date(day.date), 'dd MMM')}</div>
              </DaySelector>
            </SwiperSlide>
          ))}
        </Swiper>
      </DaysSwiperContainer>
    </>
  )
}
