import React from 'react'
import styled from 'styled-components'

import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { PaymentCard } from '@src/components/PaymentCard/PaymentCard'
import {
  Card,
  usePaymentCardsQuery,
} from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'

export const PaymentCards: React.FC<{
  onSelect?: (card: Card) => void
}> = () => {
  const { data, loading, error, refetch } = usePaymentCardsQuery()

  if (loading) return <LoadingSpinner />
  if (!data || error) return <ErrorPage logError={error} />

  return (
    <PaymentCardsList>
      {(data.customerDetails.cards.cards || []).map(
        (card: Card, index: number) => (
          <PaymentCardListItem key={card.id}>
            <PaymentCard card={card} refetchList={refetch} index={index} />
          </PaymentCardListItem>
        )
      )}
    </PaymentCardsList>
  )
}

const PaymentCardsList = styled.ul`
  padding: 0;
  list-style: none;
`

const PaymentCardListItem = styled.li`
  background-color: white;
  margin: 16px 0px;
  padding: 0 0px 16px 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebecf0;
`
