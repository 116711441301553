import { useMutation, useQuery } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { CountryCodeType } from '@src/../__generated__/globalTypes'
import { ButtonType } from '@src/components/Button/ButtonTypesEnum'
import { LoaderCheck } from '@src/components/Check/LoaderCheck'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { SelectInput, TextInput } from '@src/components/Inputs'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'

import {
  ConfirmContainer,
  Container,
  Description,
  PostcodeInput,
  StyledButton,
  Subtitle,
  SubtitleText,
  TitleText,
} from './AddBusinessStyle'
import { addBusinessApplicationDocument } from './mutations/__generated__/addBusinessApplication.graphql-interface'
import { getMarketplaceNameAddBusinessDocument } from './queries/__generated__/getMarketplaceNameAddBusiness.graphql-interface'
import { addBusinessSchema } from './validation/addBusinessValidation'

import { BurgerSection } from '../../Drawer/DrawerHeader'

const initialValues = {
  businessName: '',
  firstLine: '',
  secondLine: '',
  city: '',
  countryCode: CountryCodeType.GB,
  postcode: '',
  ownerName: '',
  businessEmail: '',
  businessPhone: '',
}

export const AddBusiness: React.FC = () => {
  const availableCountries = [{ value: 'GB', name: 'United Kingdom' }]
  const { t } = useTranslation('addBusiness')
  const [sentMessage, setSentMessage] = useState(false)

  const { data, loading, error } = useQuery(
    getMarketplaceNameAddBusinessDocument,
    {
      variables: {
        cname: window.location.hostname,
      },
    }
  )
  const [addBusiness, { loading: mutationLoading }] = useMutation(
    addBusinessApplicationDocument
  )

  if (loading) return <LoadingSpinner />
  if (error) return <ErrorPage logError={error} />
  if (!data) return <ErrorPage />

  return data.marketplaceByCname.allowOnboarding ? (
    <BurgerSection
      header={{
        title: sentMessage ? '' : t('add_business'),
      }}
    >
      <Container>
        {sentMessage ? (
          <ConfirmContainer>
            <LoaderCheck />
            <TitleText>{t('request_sent')}</TitleText>
            <SubtitleText>
              {t('request_sent_desc', {
                marketplaceName: data.marketplaceByCname.name,
              })}
            </SubtitleText>
          </ConfirmContainer>
        ) : (
          <>
            <Description>
              {!!data.marketplaceByCname.onboardingDescription &&
              data.marketplaceByCname.onboardingDescription.length > 10
                ? data.marketplaceByCname.onboardingDescription
                : t('add_business_desc', {
                    marketplaceName: data.marketplaceByCname.name,
                  })}
            </Description>
            <Formik
              validationSchema={toFormikValidationSchema(addBusinessSchema)}
              initialValues={initialValues}
              validateOnBlur={false}
              onSubmit={async values => {
                const {
                  businessName,
                  firstLine,
                  secondLine,
                  city,
                  countryCode,
                  postcode,
                  ownerName,
                  businessEmail,
                  businessPhone,
                } = values
                const addBusinessResponse = await addBusiness({
                  variables: {
                    name: businessName,
                    contactName: ownerName,
                    contactAddress: {
                      firstLine,
                      secondLine,
                      city,
                      countryCode: countryCode ?? CountryCodeType.GB,
                      postcode,
                    },
                    contactEmail: businessEmail,
                    contactPhone: businessPhone,
                    marketplaceKey: data.marketplaceByCname.key,
                  },
                })
                if (!addBusinessResponse.errors) {
                  setSentMessage(true)
                  ReactPixel.track('SubmitApplication', {
                    content_name: businessName,
                  })
                }
              }}
            >
              <Form>
                <Subtitle>{t('business_name.label')}</Subtitle>
                <TextInput
                  name="businessName"
                  label={t('business_name.label')}
                  disabled={mutationLoading}
                  autoComplete="off"
                  required
                  focusOnLoad
                />

                <Subtitle>{t('business_details')}</Subtitle>

                <TextInput
                  name="ownerName"
                  label={t('owner_name.label')}
                  disabled={mutationLoading}
                  required
                />
                <TextInput
                  name="businessEmail"
                  label={t('business_email.label')}
                  disabled={mutationLoading}
                  required
                />
                <TextInput
                  name="businessPhone"
                  type="phone-number"
                  required={true}
                  label={t('business_phone.label')}
                  disabled={mutationLoading}
                />

                <Subtitle>{t('business_address.label')}</Subtitle>
                <TextInput
                  name="firstLine"
                  label={t('business_address.first_line.label')}
                  disabled={mutationLoading}
                  autoComplete="off"
                  required
                />
                <TextInput
                  name="secondLine"
                  label={t('business_address.second_line.label')}
                  disabled={mutationLoading}
                  autoComplete="off"
                  required
                />
                <TextInput
                  name="city"
                  label={t('business_address.city.label')}
                  disabled={mutationLoading}
                  autoComplete="off"
                  required
                />
                <PostcodeInput>
                  <TextInput
                    name="postcode"
                    label={t('business_address.postcode.label')}
                    disabled={mutationLoading}
                    autoComplete="off"
                    required
                    toUpperCase
                  />
                </PostcodeInput>
                <SelectInput
                  name="countryCode"
                  label={t('business_address.country_code.label')}
                  options={availableCountries}
                  required
                />

                <StyledButton
                  buttonType={ButtonType.PRIMARY}
                  type="submit"
                  content={t('submit')}
                  loading={mutationLoading}
                />
              </Form>
            </Formik>
          </>
        )}
      </Container>
    </BurgerSection>
  ) : (
    <></>
  )
}
