import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation, TFunction } from 'react-i18next'
import { toast } from 'react-toastify'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button, ButtonType } from '@src/components/Button/Button'
import { BurgerSection } from '@src/components/Drawer/DrawerHeader'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Helmet } from '@src/components/Helmet'
import { TextInput } from '@src/components/Inputs'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { LogoutSVG } from '@src/components/SVGS/LogoutSVG'
import { useCustomerDetailsAndAddressesQuery } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import {
  DetailsChildName,
  MainRouteName,
  useAccountRouter,
} from '@src/hooks/useAccountRouter'
import { useNotLoggedInRedirect } from '@src/hooks/useNotLoggedInRedirect'
import { logout } from '@src/utils/logout'

import {
  ContentDiv,
  FormContainer,
  FormCopyText,
  StyledLogoutSVG,
  UpdateButton,
  DeleteText,
} from './ContactDetails.styles'
import { archiveCustomerAccountDocument } from './mutations/__generated__/archiveCustomerAccount.graphql-interface'
import { ContactDetailsEditCustomerDetailsDocument } from './mutations/__generated__/ContactDetailsEditCustomerDetails.graphql-interface'
import { contactDetailsValidation } from './validation/contactDetailsValidation'

const Content: React.FC<{ t: TFunction<'contactDetails'> }> = ({ t }) => {
  useNotLoggedInRedirect()
  const [alertOpen, setAlertOpen] = useState(false)
  const [logoutAlertOpen, setLogoutAlertOpen] = useState(false)
  const { setRoute } = useAccountRouter()

  const { data, loading, error } = useCustomerDetailsAndAddressesQuery()

  const [archiveCustomerAccount, { loading: deletingAccount }] = useMutation(
    archiveCustomerAccountDocument,
    {
      onError(error) {
        toast.error(error.message)
      },
    }
  )

  const [editContactDetails, { loading: editing }] = useMutation(
    ContactDetailsEditCustomerDetailsDocument
  )

  if (loading) {
    return <LoadingSpinner />
  }

  if (!data) {
    return <ErrorPage />
  }

  if (error) {
    return <ErrorPage logError={error} />
  }

  const actionButton = {
    text: t('delete_account_confirm'),
    isDanger: true,
    isLoading: deletingAccount,
    onClick: async () => {
      await archiveCustomerAccount()
      // Close alert otherwise alert flashes while switching to login screen
      setAlertOpen(false)
      toast.success(t('account_deleted'))
      await logout()
      setRoute(null)
    },
  }

  const logoutActionButton = {
    text: t('sign_out_confirm'),
    isDanger: true,
    onClick: async () => {
      // Close alert otherwise alert flashes while switching to login screen
      setAlertOpen(false)
      await logout()
      setRoute(null)
    },
  }

  const disable = editing

  return (
    <>
      <AlertModal
        isOpen={alertOpen}
        title={t('delete_account_title')}
        subTitle={t('delete_account_desc')}
        action={actionButton}
        cancel={{
          text: t('cancel'),
          onClick: () => setAlertOpen(false),
        }}
        dataTestId="delete-account-confirm-button"
      />
      <AlertModal
        isOpen={logoutAlertOpen}
        title={t('sign_out_title')}
        subTitle={t('sign_out_desc')}
        action={logoutActionButton}
        cancel={{
          text: t('cancel'),
          onClick: () => setLogoutAlertOpen(false),
        }}
        dataTestId="sign-out-confirm-submit-button"
      />
      <FormCopyText>{t('change_or_update_your_contact_details')}</FormCopyText>
      <FormContainer>
        <Formik
          validationSchema={toFormikValidationSchema(contactDetailsValidation)}
          initialValues={{
            firstName: data.customerDetails.firstName,
            lastName: data.customerDetails.lastName,
            email: data.customerDetails.email,
            phoneNumber: data.customerDetails.phoneNumber,
          }}
          onSubmit={async values => {
            await editContactDetails({
              variables: {
                firstName: values.firstName || '',
                lastName: values.lastName || '',
                phoneNumber: values.phoneNumber,
              },
            })
            setRoute({
              mainRouteName: MainRouteName.MENU,
              childRouteName: undefined,
            })
          }}
        >
          <Form>
            <TextInput
              name="firstName"
              label={t('first_name')}
              required
              disabled={disable}
            />

            <TextInput
              name="lastName"
              label={t('last_name')}
              required
              disabled={disable}
            />

            <TextInput
              name="email"
              label={t('email_address')}
              required
              disabled={true}
            />

            <TextInput
              name="phoneNumber"
              required
              label={t('mobile_number')}
              disabled={disable}
            />
            <UpdateButton
              type="submit"
              content={t('update')}
              disabled={disable}
              loading={editing}
            />
          </Form>
        </Formik>
      </FormContainer>
      <Button
        buttonType={ButtonType.SECONDARY}
        content={t('change_password')}
        onClick={() => {
          setRoute({
            mainRouteName: MainRouteName.DETAILS,
            childRouteName: DetailsChildName.PASSWORD_CHANGE,
          })
        }}
        disabled={disable}
      />
      <ContentDiv textAlign={'center'}>
        <StyledLogoutSVG>
          <LogoutSVG id="logoutSVG" />
        </StyledLogoutSVG>

        <Button
          buttonType={ButtonType.DANGER}
          content={t('sign_out')}
          onClick={() => setLogoutAlertOpen(true)}
          dataTestId="sign-out-submit-button"
        />
      </ContentDiv>
      <DeleteText
        onClick={() => setAlertOpen(true)}
        data-test-id="delete-account"
      >
        {t('delete_account')}
      </DeleteText>
    </>
  )
}

export const ContactDetails: React.FC = () => {
  const { t } = useTranslation('contactDetails')

  return (
    <>
      <Helmet title={t('my_account')} />
      <BurgerSection header={{ title: t('my_account') }}>
        <Content t={t} />
      </BurgerSection>
    </>
  )
}
