import { ApolloProvider, useReactiveVar } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useReducer } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { StyleSheetManager } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { apolloClient } from '@src/apolloClient'
import { ErrorBoundary } from '@src/components/ErrorBoundry'
import { Helmet } from '@src/components/Helmet'
import { initialiseFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/initialise'
import { fulfilmentFilterReactiveVar } from '@src/hooks/useFulfilmentFilter/utils/reactiveVar'
import { MarketplaceProvider, useMarketplace } from '@src/hooks/useMarketplace'
import { jwtVar } from '@src/models/customer/jwt'

import { LoadingSpinner } from '../components/Loaders/LoadingSpinner'
import { CaptureWindowResize } from './CaptureWindowResize'
import { GlobalStyles } from './GlobalStyles'
import { LocalStorageSync } from './LocalStorageSync'
import { PageWrapper } from './PageWrapper'
import { MarketplaceRoutes } from './Routes/MarketplaceRoutes'
import { VendorRoutes } from './Routes/VendorRoutes'
import {
  MarketplaceThemeLoader,
  BusinessThemeLoader,
} from './theme/ThemeLoader'
import isPropValid from '@emotion/is-prop-valid'

import { useLazyCustomerDetailsAndAddressesQuery } from '../hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
const FulfilmentFilterInitialiser: React.FC = () => {
  const marketplace = useMarketplace()
  const { isInitialised } = useReactiveVar(fulfilmentFilterReactiveVar)
  // force update the component when the fulfilment filter is initialised as the component is not re-rendered when the reactive var changes
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [customerDetailsQuery] = useLazyCustomerDetailsAndAddressesQuery()

  // initialise the fulfilment filters using the marketplace
  useEffect(() => {
    const initialise = async () => {
      const token = jwtVar()

      // fetch customer details and if available, use default address to initialise fulfilment filter
      let defaultAddressId = null
      if (token) {
        try {
          const { data: customerDetails } = await customerDetailsQuery()
          defaultAddressId =
            customerDetails?.customerDetails.deliveryAddresses.find(
              address => address.default
            )?.id || null
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(
            err,
            'Failed to fetch customer details. Defaulting to everywhere.'
          )
        }
      }
      initialiseFulfilmentFilter(marketplace, defaultAddressId, forceUpdate)
    }
    if (!isInitialised) {
      void initialise()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetailsQuery, isInitialised])

  useEffect(() => {
    const token = jwtVar()

    // run on first render - regardless of fulfilment filter initialisation
    let customerId
    try {
      customerId =
        (token && jwtDecode<{ customer_id: string }>(token)?.customer_id) ||
        undefined
    } catch {
      customerId = undefined
    }

    const advancedMatching = {
      external_id: customerId,
    }

    if (marketplace.ga4Id) {
      ReactGA.initialize(marketplace.ga4Id)
    }

    ReactPixel.init(
      marketplace.facebookPixelId,
      advancedMatching as any, // bad lib types
      {
        debug: import.meta.env.DEV,
        autoConfig: true,
      }
    )
    ReactPixel.pageView()
  }, [marketplace.facebookPixelId, marketplace.ga4Id])

  if (!isInitialised) return <LoadingSpinner />

  if (marketplace.businessId) {
    return (
      <>
        <Helmet
          faviconImage={marketplace.faviconImage}
          keywords={marketplace.metaKeywords}
        />
        <BusinessThemeLoader businessId={marketplace.businessId}>
          <PageWrapper>
            <BrowserRouter>
              <VendorRoutes />
            </BrowserRouter>
          </PageWrapper>
        </BusinessThemeLoader>
      </>
    )
  }

  // TODO: work out why GlobalStyles and QueryParamProvider are only used in this response and not the one above
  return (
    <>
      <Helmet
        title={marketplace.title || marketplace.name}
        description={marketplace.metaDescription}
        keywords={marketplace.metaKeywords}
        faviconImage={marketplace.faviconImage}
        storeURLApple={marketplace.storeURLApple}
      />
      <MarketplaceThemeLoader marketplaceId={marketplace.id}>
        <GlobalStyles />
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <PageWrapper>
              <MarketplaceRoutes />
            </PageWrapper>
          </QueryParamProvider>
        </BrowserRouter>
      </MarketplaceThemeLoader>
    </>
  )
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: any, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

export const App: React.FC = () => (
  <ErrorBoundary>
    <ToastContainer
      newestOnTop
      hideProgressBar
      autoClose={4000}
      draggable={false}
    />
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ApolloProvider client={apolloClient}>
        <CaptureWindowResize>
          <LocalStorageSync>
            <MarketplaceProvider>
              <FulfilmentFilterInitialiser />
            </MarketplaceProvider>
          </LocalStorageSync>
        </CaptureWindowResize>
      </ApolloProvider>
    </StyleSheetManager>
  </ErrorBoundary>
)
