export const serviceNavigation = {
  filter: 'Filter',
  near_me: 'Near Me',
  when: 'When',
  where: 'Where',
  service: 'Service',
  delivery: 'Delivery',
  collection: 'Collection',
  all: 'All',
  all_restaurants: 'All Restaurants',
  all_outlets: 'All Outlets',
  all_takeaways: 'All Takeaways',
  table: 'Table Service',
  today: 'Today',
  asap: 'ASAP',
  asap_by: 'ASAP by',
  search_restaurant: 'Search restaurant or cuisine...',
  search_retail: 'Search business or product...',
  search_for_item: 'Search...', // "Search the menu..." Doesn't fit the container in smaller windows/screens
  search_results: 'Search Results for "{{searchTerm}}"',
  nothing_matches_search: 'Sorry, nothing matches your search.',
  anytime: 'Anytime',
  preorder: 'Preorder',
  now: 'Now',
  tomorrow: 'Tomorrow',
  schedule: 'Schedule',
  arriving_by: 'Arriving by',
  ready_by: 'Ready by',
  closed: 'Closed',
  select_a_location: 'Select a Location',
  select_a_table: 'Select a Table',

  service_header: 'Service',
  service_subheader: 'How would you like to receive your order?',
  service_subheader_closed: 'What menu would you like to view?',
  list_service_header: 'Service',
  list_service_subheader: 'What are you searching for?',
  time_header: 'When',
  time_subheader: 'What time and day do you need your order?',
  list_time_header: 'When',
  list_time_subheader: 'When do you need your order?',
  location_header: 'Location',
  location_subheader: 'Where do you want to search?',
  delivery_location_subheader: 'Where do you want your order delivered?',
  list_location_header: 'Where',
  table_header: 'Table Service',
  table_subheader: 'Please select your table',
  saved_addresses: 'Saved Addresses',
  location: 'Location',
  postcode: 'Postcode',
  address: 'Address',
  everywhere: 'Everywhere',

  addresses_option_title: 'Saved Addresses',
  addresses_option_subtitle: 'Choose from your saved addresses',
  location_option_title: 'Your Location',
  location_option_subtitle: 'Use location services to find where you are',
  postcode_option_title: 'Postcode',
  postcode_option_subtitle: 'Enter a postcode to search an area',
  delivery_zone_option_title: 'Delivery Zone',
  delivery_zone_option_subtitle: 'Choose your delivery zone',
  everywhere_option_title: 'Everywhere',
  everywhere_option_subtitle: 'Show all {{urlPath}}',

  enter_postcode: 'Enter a postcode',
  find_location_postcode: 'Find a location by entering a postcode',
  select_saved_address: 'Choose from your saved addresses',
  choose_from_saved_addresses: 'Choose from one of your saved addresses',
  delivery_not_available: 'Delivery is not available for this {{locationType}}',
  all_fulfilments_title: 'All',

  select_date: 'Select a date',
  select_data_subtext: 'This is the day you would like to receive your order',

  done: 'Done',
  select: 'Select',
  change: 'Change',
  collect_from: 'Collect from',
  no_saved_addresses: 'You have no saved addresses',
  add_address: 'Add a new address',
}
