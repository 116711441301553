import React, { useEffect, useState } from 'react'
import { config, useTransition } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import {
  OptionContentLabels,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'

import { marketplaceIdServiceOverlayDocument } from '../MobileServiceOverlay/queries/__generated__/marketplaceIdServiceOverlay.graphql-interface'

export const OutletFulfilmentSection: React.FC = () => {
  const { t } = useTranslation('serviceOverlay')
  const [show, setShow] = useState(false)
  const {
    outlet,
    setCurrentFulfilmentFromNarrowFulfilment,
    data: { currentFulfilment },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })

  const { availableFulfilmentInputMethods } = outlet

  const { narrowType: currentNarrowFulfilment } = currentFulfilment

  useEffect(() => {
    setShow(true)
  }, [])

  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    config: config.default,
  })

  const marketplace = useQuery(marketplaceIdServiceOverlayDocument, {
    variables: { cname: window.location.hostname },
  })

  if (marketplace.loading || !marketplace.data) return null

  const fulfilmentLabels: {
    label: string
    value: NarrowFulfilmentMethodInputType
  }[] = availableFulfilmentInputMethods.map(method => ({
    label: t(method.toLowerCase()),
    value: method,
  }))

  return transition(
    (styles, item) =>
      item && (
        <>
          {fulfilmentLabels.map(option => (
            <PopoverOptionContainer key={option.label}>
              <UncontrolledRadioInput
                label={
                  <OptionLabel>
                    <OptionContentLabels>
                      <span>{option.label}</span>
                    </OptionContentLabels>
                  </OptionLabel>
                }
                style={CheckboxInputStyle.TICK}
                checked={currentNarrowFulfilment === option.value}
                onClick={() => {
                  setCurrentFulfilmentFromNarrowFulfilment(option.value)
                }}
              />
              {option.value === 'TABLE' &&
                currentNarrowFulfilment === 'TABLE' && (
                  <h4>{outlet.pendingTableNumbersCustomText}</h4>
                )}
            </PopoverOptionContainer>
          ))}
        </>
      )
  )
}
