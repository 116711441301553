import { useQuery } from '@apollo/client'
import { capitalize, sortBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BackToOutletListLink } from '@src/components/BackToOutletListLink/BackToOutletListLink'
import { getOutletSearchBySegmentVariables } from '@src/components/BusinessSegments/getSegmentOutletSearchVariables'
import { getBusinessSegmentDocument } from '@src/components/BusinessSegments/queries/__generated__/getBusinessSegment.graphql-interface'
import { getBusinessSegmentOutletsDocument } from '@src/components/BusinessSegments/queries/__generated__/getBusinessSegmentOutlets.graphql-interface'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { Description } from '@src/components/FeaturedOutlets/FeaturedOutlets.styles'
import { TextClamp } from '@src/components/Text/Clamp'
import { useCategories } from '@src/hooks/useCategories'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { ALL_FULFILMENT_METHODS_SORTED } from '@src/utils/fulfilment'

import { CategoryFilteredOutlets } from '../OutletList/CategoryFilteredOutlets'
import { OutletCards } from '../OutletList/OutletCards'
import {
  HeaderContainer,
  OutletsOuterContainer,
  StyledOutletCardsContainer,
} from '../OutletList/OutletList.styles'
import { OutletListSkeleton } from '../OutletList/OutletListSkeleton'
import {
  LocationType,
  NarrowFulfilmentMethodInputType,
  OrderFulfillmentMethods,
  Outlet,
  SegmentOrderBy,
} from '@src/graphql-types'
import { sortOutlets } from '@src/utils/sortOutlets'
import {
  DateifyOutlet,
  parseOutletDates,
} from '@src/utils/fulfilmentTimes/parsers'

export const SegmentOutletList: React.FC = () => {
  const fulfilmentFilter = useFulfilmentFilter()
  const marketplace = useMarketplace()
  const { segmentId } = useParams<{ segmentId: string }>()

  const { t } = useTranslation(['copy'])

  const { selectedCategories } = useCategories()

  const orderOutlets = <
    T extends DateifyOutlet<
      Pick<
        Outlet,
        | 'displayName'
        | 'availableFulfilmentInputMethods'
        | 'isOnline'
        | 'isOpen'
        | 'isOrderable'
        | 'promoteOutlet'
        | 'nextOpenDate'
      >
    >
  >(
    outlets: T[],
    orderBy: SegmentOrderBy | null
  ): T[] => {
    if (!orderBy) return outlets

    if (orderBy === SegmentOrderBy.OPEN_STATUS) {
      return sortOutlets(outlets, fulfilmentFilter.data, selectedCategories)
    }

    if (orderBy === SegmentOrderBy.ALPHABETICAL) {
      return sortBy(outlets, 'displayName')
    }

    return outlets
  }

  // gotta use state cause its not detecting selected date change
  const variables = segmentId
    ? getOutletSearchBySegmentVariables({
        fulfilmentFilterData: fulfilmentFilter.data,
        marketplaceId: marketplace.id,
        businessSegmentId: segmentId,
        limitOutlets: false,
      })
    : undefined

  const allOutletsForSegmentData = useQuery(getBusinessSegmentOutletsDocument, {
    variables: segmentId
      ? {
          fulfilmentMethod: NarrowFulfilmentMethodInputType.DELIVERY,
          input: {
            locationType: LocationType.EVERYWHERE,
            businessSegmentId: segmentId,
            marketplaceId: marketplace.id,
            fulfilmentMethods: [
              OrderFulfillmentMethods.DELIVERY,
              OrderFulfillmentMethods.COLLECTION,
              OrderFulfillmentMethods.TABLE,
            ],
          },
        }
      : undefined,
  })

  const { data, loading, error } = useQuery(
    getBusinessSegmentOutletsDocument,
    variables ? { variables } : { skip: true }
  )

  const segmentResponse = useQuery(getBusinessSegmentDocument, {
    variables: segmentId ? { id: segmentId } : undefined,
  })
  const businessSegment = segmentResponse.data?.businessSegment

  const allOutletsForSegment =
    allOutletsForSegmentData.data?.businessSegmentOutlets.map(
      ({ nextOpenDate, ...outlet }) =>
        parseOutletDates({
          nextOpenDate: new Date(nextOpenDate).toISOString(),
          ...outlet,
        })
    )
  const sortedAllOutletsForSegment =
    allOutletsForSegment?.length && businessSegment?.orderBy
      ? orderOutlets(
          allOutletsForSegment as DateifyOutlet<Outlet>[],
          businessSegment.orderBy
        )
      : allOutletsForSegment

  if (loading) {
    return <OutletListSkeleton />
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data || !businessSegment) {
    return <ErrorPage />
  }

  const outlets = data.businessSegmentOutlets.map(
    ({ nextOpenDate, ...outlet }) =>
      parseOutletDates({
        nextOpenDate: new Date(nextOpenDate).toISOString(),
        ...outlet,
      })
  )

  const sortedOutlets = orderOutlets(outlets, businessSegment?.orderBy)

  const getAllOutletsTitle = (): string => {
    if (
      fulfilmentFilter.data.nonEmptyFulfilmentMethods.length ===
      ALL_FULFILMENT_METHODS_SORTED.length
    ) {
      return marketplace.allOutletsText || t('all')
    }
    return fulfilmentFilter.data.nonEmptyFulfilmentMethods
      .map(fulfilment => capitalize(t(fulfilment.toLowerCase())))
      .join(', ')
  }

  return selectedCategories.length ? (
    <CategoryFilteredOutlets outlets={sortedOutlets} />
  ) : (
    <OutletsOuterContainer>
      <BackToOutletListLink showContainer />
      {sortedOutlets.length > 0 && (
        <HeaderContainer>
          {` ${businessSegment.name ?? ''}
            ${` - `}${getAllOutletsTitle()}`}
        </HeaderContainer>
      )}
      {!!businessSegment.description?.length && (
        <Description>
          <TextClamp clamp={5}>{businessSegment.description}</TextClamp>
        </Description>
      )}
      <OutletCards outlets={sortedOutlets}></OutletCards>
      {!businessSegment.filterSegment &&
        sortedAllOutletsForSegment &&
        sortedAllOutletsForSegment.length !== sortedOutlets.length && (
          <StyledOutletCardsContainer>
            {
              <HeaderContainer>{`${businessSegment.name ?? ''}
            ${t('unfiltered')}`}</HeaderContainer>
            }
            <OutletCards outlets={sortedAllOutletsForSegment}></OutletCards>
          </StyledOutletCardsContainer>
        )}
    </OutletsOuterContainer>
  )
}
