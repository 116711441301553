export const signIn = {
  continue: 'Continue',
  new_customer: 'New customer?',
  sign_in: 'Sign In',
  forgot_password: 'Forgot Password?',
  required: '*Required',
  join_now: 'Join Now',
  email_address: 'Email Address',
  password: 'Password',
  sign_up: 'Create Account',
}

export const signInError = {
  please_enter_your_email_address: 'Please enter your email address',
  invalid_email_address: 'Invalid email address',
  please_enter_your_password: 'Please enter your password',
}

export const resetPassword = {
  forgot_password: 'Forgot Password?',
  copy: 'To reset your password we need to send an email to the following email address:',
  check_inbox: 'Your secure password reset email has been sent to:',
  email: {
    label: 'Email Address',
    validation: {
      required_error: 'Please enter your email address',
      invalid_email_address: 'Unrecognized email address',
    },
  },
  button: {
    reset: 'Reset Password',
    resend: 'Resend Email',
  },
  password_reset_form: {
    required_error: 'Please enter password',
    password_length: 'Password must be at least 8 characters long',
    repeat_password_error: 'Please repeat password',
    password_match_error: 'Passwords did not match',
  },
  password: 'Password',
  confirm_password: 'Confirm Password',
  update_password: 'Update Password',
  password_reset: 'Password minimum 8 characters.',
  token_doesnt_exist: 'The supplied token does not exist.',
  token_expired: 'The token expired, please reset the password again.',
  token_used: 'This token was already used.',
  link_sent: 'A link to reset your password was sent to the supplied email.',
  data_missing: 'Data is missing try following the reset password link again.',
  password_updated: 'Password updated, you are now logged in.',
}

export const changePassword = {
  change_password: 'Change Password',
  enter_new_password: 'Enter your existing password and new password.',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  update_password: 'Update Password',
  password_updated_message: 'Your password has been updated.',
  password_length: 'Password must be at least 8 characters long',
  password_match_error: 'Passwords did not match',
  required_error: 'Please enter password',
}
