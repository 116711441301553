type S3Buckets = {
  dev: string
  development: string
  test: string
  'test-eur': string
  'staging-au': string
  staging: string
  'prod-au': string
  prod: string
}

const s3Buckets: S3Buckets = {
  dev: 'redbox-gbp-img-dev',
  development: 'redbox-gbp-img-test', // We use test bucket in local dev
  test: 'redbox-gbp-img-test',
  'test-eur': 'redbox-eur-img-test-eur',
  'staging-au': 'redbox-aud-img-staging-au',
  'prod-au': 'redbox-aud-img-prod-au',
  staging: 'redbox-gbp-img-staging',
  prod: 'redbox-gbp-images-prod',
} as const

function getS3Bucket() {
  const ENVIRONMENT_NAME_LOWERCASE = import.meta.env
    .VITE_ENVIRONMENT_NAME_LOWERCASE
  // fallback to production bucket so if something goes wrong production will still serve images correctly
  if (!s3Buckets[ENVIRONMENT_NAME_LOWERCASE as keyof S3Buckets]) {
    console.warn(
      'Invalid s3 bucket configuration in env file, falling back to production bucket for ENVIRONMENT_NAME_LOWERCASE: ',
      ENVIRONMENT_NAME_LOWERCASE
    )
  }
  const fallback = s3Buckets.test
  return s3Buckets[ENVIRONMENT_NAME_LOWERCASE as keyof S3Buckets] || fallback
}

export const s3Bucket = getS3Bucket()
