import React from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '@src/components/Carousel'
import { LinkSlide } from '@src/components/LinkSlide/LinkSlide'
import { SingleOutlet } from '@src/components/SingleOutlet/types'
import { TextClamp } from '@src/components/Text/Clamp'
import { BusinessSegment, LinkCard } from '@src/graphql-types'
import { useCategories } from '@src/hooks/useCategories'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { convertToSlug } from '@src/utils/convertToSlug'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'
import { sortOutlets } from '@src/utils/sortOutlets'

import { IconButton } from '../Button/IconButton'
import {
  FeaturedOutletsContainer,
  HeroContainer,
  OutletContainer,
  SliderArrowsContainer,
  HeaderLink,
  Description,
  HeaderDiv,
} from '../FeaturedOutlets/FeaturedOutlets.styles'
import { OutletSlide } from '../OutletSlide'

export type SegmentWithOutletOrLinkCard = Pick<
  BusinessSegment,
  | 'id'
  | 'name'
  | 'showName'
  | 'orderBy'
  | 'description'
  | 'showDiscountLabels'
  | 'showFeaturedFlags'
  | 'type'
  | 'linkToSegmentIndex'
> & {
  cards: Array<OutletOrLinkCard>
}

export type OutletOrLinkCard =
  | (DateifyOutlet<SingleOutlet> & { __typename: string })
  | (LinkCard & { __typename: string })

type Props = {
  businessSegment: SegmentWithOutletOrLinkCard
}

export const BusinessSegmentOutlets: React.FC<Props> = ({
  businessSegment,
}) => {
  const { t } = useTranslation('navigation')

  const circleButtonLeftClassName = `${businessSegment.id}-segment-left`
  const circleButtonRightClassName = `${businessSegment.id}-segment-right`

  const cards = businessSegment.cards

  const fulfilmentFilter = useFulfilmentFilter()
  const { selectedCategories } = useCategories()

  function sortByOpenStatus(
    cards: Array<
      | (DateifyOutlet<SingleOutlet> & { __typename?: string })
      | (LinkCard & { __typename?: string })
    >
  ): Array<
    | (DateifyOutlet<SingleOutlet> & { __typename?: string })
    | (LinkCard & { __typename?: string })
  > {
    // Separate outlets and store their indices
    const linkCardsWithIndexes: { card: LinkCard; index: number }[] = []
    const outlets: Array<
      DateifyOutlet<SingleOutlet> & {
        __typename: string
      }
    > = []

    // Extract and sort the outlets
    cards.map((card, index) => {
      if (card.__typename !== 'Outlet') {
        linkCardsWithIndexes.push({ card: card as LinkCard, index })
        return null
      }
      return outlets.push(
        card as DateifyOutlet<SingleOutlet> & {
          __typename: string
        }
      )
    })

    // Sort the outlets
    const sortedOutlets = sortOutlets(
      outlets.filter(outlet => !!outlet),
      fulfilmentFilter.data,
      selectedCategories
    )

    // Re-create the sorted card array
    const sortedCards: Array<
      | (DateifyOutlet<SingleOutlet> & { __typename?: string })
      | (LinkCard & { __typename?: string })
    > = [...sortedOutlets]
    // Re-insert the link cards into their original positions
    linkCardsWithIndexes.forEach(linkCard => {
      sortedCards.splice(linkCard.index, 0, linkCard.card)
    })

    return sortedCards
  }

  const sortedCards =
    businessSegment.orderBy === 'OPEN_STATUS' ? sortByOpenStatus(cards) : cards

  return (
    <>
      {cards?.length > 0 && (
        <OutletContainer>
          <HeroContainer>
            {businessSegment.showName && (
              <>
                {businessSegment.linkToSegmentIndex ? (
                  <HeaderLink
                    to={`/segments/${businessSegment.id}/${convertToSlug(
                      businessSegment.name
                    )}`}
                  >
                    <h2>{businessSegment.name}</h2>
                  </HeaderLink>
                ) : (
                  <HeaderDiv>
                    <h2>{businessSegment.name}</h2>
                  </HeaderDiv>
                )}
              </>
            )}
            <SliderArrowsContainer>
              <IconButton
                className={circleButtonLeftClassName}
                size={32}
                icon={'caretLeft'}
              />
              <IconButton
                className={circleButtonRightClassName}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </SliderArrowsContainer>
          </HeroContainer>
          {!!businessSegment.description?.length && (
            <Description>
              <TextClamp clamp={5}>{businessSegment.description}</TextClamp>
            </Description>
          )}

          <FeaturedOutletsContainer>
            <Carousel
              buttonLeftClassName={circleButtonLeftClassName}
              buttonRightClassName={circleButtonRightClassName}
              slides={sortedCards.map(card =>
                card.__typename === 'Outlet' ? (
                  <OutletSlide
                    key={`segmentOutlet-${businessSegment.id}-${card.id}`}
                    outlet={card}
                    showDiscountLabels={businessSegment.showDiscountLabels}
                    showFeaturedFlags={businessSegment.showFeaturedFlags}
                  />
                ) : (
                  <LinkSlide linkCard={card as LinkCard} />
                )
              )}
            />
          </FeaturedOutletsContainer>
        </OutletContainer>
      )}
    </>
  )
}
