import { LazyQueryExecFunction } from '@apollo/client'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletAvailabilityQuery,
  OutletAvailabilityQueryVariables,
} from '@src/hooks/sharedQueries/outletAvailabilityQuery/queries/__generated__/OutletAvailability.graphql-interface'
import { debugLogger } from '@src/utils/debugLogger'

import { calculateFulfilmentTimesFromASAPTime } from './updateFulfilmentTimesIfExpired/calculateFulfilmentTimesFromASAPTime'
import { calculateFulfilmentTimesFromPreorderTimes } from './updateFulfilmentTimesIfExpired/calculateFulfilmentTimesFromPreorderTimes'

import { HookMethodArgs } from '../types/types'
import { HistoricalData } from '../validation'

export type UpdateFulfilmentTimesIfExpired = () => void
export const updateFulfilmentTimesIfExpired =
  (
    hookMethodArgs: HookMethodArgs & {
      outletAvailabilityQuery: LazyQueryExecFunction<
        OutletAvailabilityQuery,
        OutletAvailabilityQueryVariables
      >
      unsavedCurrentFulfilmentType: string | undefined
    }
  ): UpdateFulfilmentTimesIfExpired =>
  async () => {
    // if the outlet is not orderable, then there's no need to update the fulfilment times
    if (!hookMethodArgs.outlet.isOrderable) {
      debugLogger('Outlet is not orderable. No need to update fulfilment times')
      return
    }

    if (
      hookMethodArgs.unsavedCurrentFulfilmentType &&
      hookMethodArgs.unsavedCurrentFulfilmentType === 'TABLE'
    ) {
      debugLogger('Table is selected. No need to update fulfilment times')
      return
    }

    // get the existing collection and delivery preorder times
    const {
      collectionPreorderDatetime: existingCollectionPreorderDatetime,
      deliveryPreorderWindow: existingDeliveryPreorderWindow,
    } = hookMethodArgs.existingData.historicalData

    let updateData
    if (
      !existingCollectionPreorderDatetime &&
      !existingDeliveryPreorderWindow
    ) {
      updateData = await calculateFulfilmentTimesFromASAPTime(hookMethodArgs)
    } else {
      updateData = await calculateFulfilmentTimesFromPreorderTimes({
        hookMethodArgs,
        existingCollectionPreorderDatetime,
        existingDeliveryPreorderWindow,
      })
    }
    const collectionPreorderDatetimeHasChanged =
      updateData.collectionPreorderDatetime?.valueOf() !==
      existingCollectionPreorderDatetime?.valueOf()
    const deliveryPreorderWindowHasChanged =
      updateData.deliveryPreorderWindow?.start.valueOf() !==
      existingDeliveryPreorderWindow?.start.valueOf()

    // nothing to update
    if (
      !collectionPreorderDatetimeHasChanged &&
      !deliveryPreorderWindowHasChanged
    ) {
      debugLogger('No changes to fulfilment times needed')
      return
    }

    // only update current fulfilment if it's collection
    // and the collection preorder time has changed
    let currentFulfilment = hookMethodArgs.existingData.currentFulfilment
    if (
      currentFulfilment.narrowType ===
        NarrowFulfilmentMethodInputType.COLLECTION &&
      collectionPreorderDatetimeHasChanged &&
      updateData.collectionPreorderDatetime !== undefined
    ) {
      debugLogger('Updating collection preorder time')
      currentFulfilment = {
        ...currentFulfilment,
        collectionPreorderDatetime: updateData.collectionPreorderDatetime,
      }
    }
    // or if it's delivery and the delivery preorder window has changed
    else if (
      currentFulfilment.narrowType ===
        NarrowFulfilmentMethodInputType.DELIVERY &&
      deliveryPreorderWindowHasChanged &&
      updateData.deliveryPreorderWindow !== undefined
    ) {
      debugLogger('Updating delivery preorder window')
      currentFulfilment = {
        ...currentFulfilment,
        deliveryPreorderWindow: updateData.deliveryPreorderWindow,
      }
    }

    // always update historical data
    const historicalData: HistoricalData = {
      ...hookMethodArgs.existingData.historicalData,
      ...(updateData.collectionPreorderDatetime !== undefined && {
        collectionPreorderDatetime: updateData.collectionPreorderDatetime,
      }),
      ...(updateData.deliveryPreorderWindow && {
        deliveryPreorderWindow: updateData.deliveryPreorderWindow,
      }),
    }

    debugLogger('Saving changes', {
      existingCollectionPreorderDatetime,
      existingDeliveryPreorderWindow,
      updateData,
      collectionPreorderDatetimeHasChanged,
      deliveryPreorderWindowHasChanged,
      currentFulfilment,
      historicalData,
    })
    // update reactive var with merged data
    hookMethodArgs.updateDataFn({
      ...hookMethodArgs.existingData,
      currentFulfilment,
      historicalData,
    })
  }
