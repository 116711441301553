import React, { Dispatch, SetStateAction, useCallback } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'

import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { SpicyChiliSVG } from '@src/components/SVGS/SpicyChiliSVG'
import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import {
  ItemDetailsHeader,
  MenuDescription,
} from '@src/pages/OutletPage/OutletMenu/MenuItem/MenuItem.styles'
import {
  AddOnDetailsColumn,
  AddOnItemContainer,
  AddOnItemDetails,
  AddOnItemPrice,
  AddOnItemRegularPrice,
  AddOnPriceContainer,
  AllergenTagHolder,
  ChilliContainer,
  DetailsContainer,
  HeaderHolder,
  ImageContainer,
  ItemImage,
} from './AddOnItems.styles'

import { BasketItem } from '@src/hooks/useBasketItems/validation'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import { imageJitURL } from '@src/utils/imageJitURL'
import { Allergen } from '@src/graphql-types'
import { addOnMenuItems } from './queries/__generated__/addOnMenuItems'

type AddOnItemsProps = {
  addOnItem: addOnMenuItems['addOnMenuItems'][0]
  setDirty: Dispatch<SetStateAction<boolean>>
}

export const AddOnItemCard = ({ addOnItem, setDirty }: AddOnItemsProps) => {
  const { addItem, removeItem, updateQuantity, items } = useBasketItems()
  const { basketOutletId } = useBasketOutletId()
  const formatCurrency = useFormatCurrency()
  const { t } = useTranslation('addOnItems')

  const selectAddOnItem = useCallback(
    (item: Omit<BasketItem, 'id' | 'availability'>) => {
      addItem({
        item,
        outletId: basketOutletId || '',
      })
      setDirty(true)
      ReactPixel.track('AddToCart', {
        content_ids: [item.menuItemId],
        content_type: 'product',
        content_name: 'AddOnItems',
        contents: {
          name: item.name,
          id: item.menuItemId,
          price: item.price,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addItem, basketOutletId]
  )

  const {
    name,
    id: outletMenuItemId,
    menuItemId,
    price,
    addOnMaxPrice,
    isDairyFree,
    isGlutenFree,
    isVegan,
    isVegetarian,
    isKeto,
    ageRestricted,
    description,
    image,
    maxPurchaseQuantity,
  } = addOnItem

  const presentAllergens = () => {
    const allergensArr = []
    if (isDairyFree) allergensArr.push(Allergen.DAIRY_FREE)
    if (isGlutenFree) allergensArr.push(Allergen.GLUTEN_FREE)
    if (isVegan) allergensArr.push(Allergen.VEGAN)
    if (isVegetarian) allergensArr.push(Allergen.VEGETARIAN)
    if (isKeto) allergensArr.push(Allergen.KETO)
    return allergensArr
  }

  const itemImage = imageJitURL(image, {
    resize: {
      width: 128,
      height: 128,
      fit: 'cover',
    },
  })

  const quantityOfSelectedItem =
    items.find(item => item.menuItemId === menuItemId)?.quantity || 0

  const basketItemId = items.find(item => item.menuItemId === menuItemId)?.id
  return (
    <AddOnItemContainer
      key={outletMenuItemId}
      noHover={quantityOfSelectedItem === 0}
      onClick={() => {
        if (quantityOfSelectedItem === 0)
          selectAddOnItem({
            menuItemId,
            price,
            quantity: 1,
            outletMenuItemId,
            name,
            optionItemIds: [],
            singleItemNotes: '',
          })
      }}
    >
      <AddOnDetailsColumn>
        <HeaderHolder>
          <ItemDetailsHeader>{name}</ItemDetailsHeader>
          <ChilliContainer>
            {addOnItem.spiceLevel > 0 &&
              Array.from({ length: addOnItem.spiceLevel }).map((_, index) => (
                <SpicyChiliSVG width={15} height={15} key={index} />
              ))}
          </ChilliContainer>
        </HeaderHolder>
        <AllergenTagHolder>
          <AllergenTags
            allergens={presentAllergens()}
            iconView
            ageRestricted={!!ageRestricted}
          />
        </AllergenTagHolder>
        {description && <MenuDescription>{description}</MenuDescription>}
        <AddOnItemDetails selectedAnItem={quantityOfSelectedItem > 0}>
          <AddOnPriceContainer>
            <AddOnItemRegularPrice>
              {addOnMaxPrice ? formatCurrency(addOnMaxPrice) : null}
            </AddOnItemRegularPrice>
            <AddOnItemPrice>{formatCurrency(price)}</AddOnItemPrice>
          </AddOnPriceContainer>
          <ItemCounter
            allowAddItem={
              maxPurchaseQuantity === null ||
              quantityOfSelectedItem < maxPurchaseQuantity
            }
            addItemText={
              maxPurchaseQuantity !== null &&
              quantityOfSelectedItem >= maxPurchaseQuantity
                ? t('maximum_addon_purchase_quantity')
                : t('add_offer')
            }
            dataTooltipId="page-wrapper-tooltip"
            allowZeroCount={false}
            oldPrice={addOnMaxPrice ?? undefined}
            hidePrice
            count={quantityOfSelectedItem}
            isMenuItem
            price={price}
            handleAdd={() => {
              if (quantityOfSelectedItem > 0)
                selectAddOnItem({
                  menuItemId,
                  price,
                  quantity: 1,
                  outletMenuItemId,
                  name,
                  optionItemIds: [],
                  singleItemNotes: '',
                })
            }}
            handleRemove={() => {
              if (basketItemId) {
                if (quantityOfSelectedItem === 1) {
                  removeItem({
                    basketItemId,
                    shouldNavigateToBasket: false,
                  })
                } else {
                  updateQuantity({
                    basketItemId,
                    quantity: quantityOfSelectedItem - 1,
                    shouldNavigateToBasket: false,
                  })
                }
              }
            }}
          />
        </AddOnItemDetails>
      </AddOnDetailsColumn>
      <ImageContainer>
        <ItemImage role="img" aria-label={name} imageURL={itemImage} />
        <DetailsContainer />
      </ImageContainer>
    </AddOnItemContainer>
  )
}
