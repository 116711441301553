import React from 'react'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import {
  OptionContentLabels,
  OptionContentText,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'

import { LoadingSpinner } from '../Loaders/LoadingSpinner'

export const TableSection: React.FC = () => {
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.LOCAL,
  })

  const selectedTableId = outletFulfilment.data.historicalData.tableId

  const { data, loading, error } = useTablesQuery({
    outletId: outletFulfilment.outlet.id,
  })

  // TODO: Skeleton loading
  if (loading) {
    return <LoadingSpinner />
  }

  // TODO: Nice error
  if (error || !data) {
    // eslint-disable-next-line no-console
    console.error(error)
    return <span>{'An unexpected error occurred'}</span>
  }

  return (
    <>
      {data.tables.map((table, index) => (
        <PopoverOptionContainer key={table.id}>
          <UncontrolledRadioInput
            label={
              <>
                <OptionLabel>
                  <OptionContentLabels>
                    <OptionContentText>{table.friendlyName}</OptionContentText>
                  </OptionContentLabels>
                </OptionLabel>
              </>
            }
            style={CheckboxInputStyle.TICK}
            checked={selectedTableId === table.id}
            onClick={() => {
              outletFulfilment.setCurrentFulfilment({
                type: CurrentFulfilmentType.TABLE,
                tableId: table.id,
              })
            }}
            dataTestId={`table-option-${index}`}
          />
        </PopoverOptionContainer>
      ))}
    </>
  )
}
