import React, { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { StyledCheckoutButton } from '@src/components/CheckoutModal/CheckoutButton.styles'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import { LoadingSpinner } from '@src/components/Loaders/LoadingSpinner'
import { AddOnItemCard } from './AddOnItemCard'
import { Container, Description } from './AddOnItems.styles'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'
import {
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod,
} from '@src/graphql-types'
import { addOnMenuItemsDocument } from './queries/__generated__/addOnMenuItems.graphql-interface'

// TODO sorry i feel like this should already exist but i cant find it
const ChosenFulfilmentToMenuItemGroupFulfillmentMethod: Record<
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod
> = {
  [NarrowFulfilmentMethodInputType.DELIVERY]:
    MenuItemGroupFulfillmentMethod.DELIVERY,
  [NarrowFulfilmentMethodInputType.COLLECTION]:
    MenuItemGroupFulfillmentMethod.COLLECTION,
  [NarrowFulfilmentMethodInputType.TABLE]: MenuItemGroupFulfillmentMethod.TABLE,
}

export const AddOnItems: React.FC<{
  setManualLoadingState: Dispatch<SetStateAction<boolean>>
}> = ({ setManualLoadingState }) => {
  const { override } = useCheckoutRouter()
  const { t } = useTranslation('addOnItems')
  const basketTotals = useBasketTotals()

  const {
    outlet,
    data: { currentFulfilment },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const { data, loading } = useQuery(
    addOnMenuItemsDocument,
    basketTotals.data?.checkoutBasketTotals
      ? {
          variables: {
            outletId: outlet.id,
            subTotal:
              basketTotals.data.checkoutBasketTotals.nonAddOnBasketItemsTotal,
            fulfilmentMethods: [
              ChosenFulfilmentToMenuItemGroupFulfillmentMethod[
                currentFulfilment.narrowType
              ],
            ],
            endOfPrep: currentFulfilment.endOfPrep?.toISOString(),
          },
          onCompleted: data => {
            setManualLoadingState(false)
            if (!data.addOnMenuItems.length) {
              override(CheckoutRoute.PAYMENT)
            }
          },
          onError: _error => {
            override(CheckoutRoute.PAYMENT)
          },
        }
      : { skip: true }
  )

  const [dirty, setDirty] = useState(false)

  if (loading || !basketTotals.data?.checkoutBasketTotals) {
    setManualLoadingState(loading)
    return <LoadingSpinner />
  }

  if (!data || !data.addOnMenuItems.length) {
    return null
  }

  const { name: restaurantName } = outlet.restaurant

  return (
    <Fragment>
      <Container windowHeight={window.innerHeight}>
        <Description>{t('offers_desc', { restaurantName })}</Description>
        {data.addOnMenuItems.map(addOnItem => (
          <AddOnItemCard
            key={addOnItem.id}
            addOnItem={addOnItem}
            setDirty={setDirty}
          />
        ))}
      </Container>
      <CheckoutButtonWrapperMobile>
        <StyledCheckoutButton
          content={dirty ? t('continue') : t('no_thanks')}
          onClick={() => {
            void override(CheckoutRoute.PAYMENT)
          }}
          dataTestId="checkout-mobile-modal"
        />
      </CheckoutButtonWrapperMobile>
    </Fragment>
  )
}
