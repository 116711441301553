import React, { FC, Children, Fragment, ReactNode } from 'react'
import { DotSpan } from './DotSpan'

// Created to prevent trailing separators in certain dynamic lists, over the use of conditionals where applied
// Also makes places where used more modular for moving and adding components
export const DotSeparatedList: FC<{
  children: ReactNode[]
  dotSpan?: ReactNode
}> = ({ children, dotSpan = <DotSpan /> }) => {
  const activeSections = Children.toArray(children).filter(Boolean)

  return (
    <Fragment>
      {activeSections.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index < activeSections.length - 1 && dotSpan}
        </Fragment>
      ))}
    </Fragment>
  )
}
