import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type outletFulfilmentQueryVariables = Types.Exact<{
  outletId: Types.Scalars['String']
  showInactive: Types.Scalars['Boolean']
  acceptedFulfilmentMethods?: Types.InputMaybe<
    | Array<Types.NarrowFulfilmentMethodInputType>
    | Types.NarrowFulfilmentMethodInputType
  >
  fulfilmentLocation?: Types.InputMaybe<Types.FulfilmentLocationInput>
}>

export type outletFulfilmentQuery = {
  outlet: {
    __typename: 'Outlet'
    id: string
    displayName: string
    description: string | null
    closedUntil: string | null
    coverImage: string | null
    promoteOutlet: boolean
    outletLogoOverride: string | null
    deliveryMinimumOrderValue: number | null
    collectionMinimumOrderValue: number | null
    daysOfferedInAdvanceMin: number
    daysOfferedInAdvanceMax: number
    daysOfferedInAdvanceMinDelivery: number
    daysOfferedInAdvanceMaxDelivery: number
    daysOfferedInAdvanceMinCollection: number
    daysOfferedInAdvanceMaxCollection: number
    paymentMethods: Array<string>
    outletPhone: string | null
    hiddenMenuItemIds: Array<string>
    hiddenMenuItemGroupIds: Array<string>
    availableFulfilmentInputMethods: Array<Types.NarrowFulfilmentMethodInputType>
    nowFilter: Types.OutletNowFilter
    preorderDeliveryAllowed: boolean
    preorderCollectionAllowed: boolean
    prioritisedFulfilmentMethods: Array<Types.NarrowFulfilmentMethodInputType>
    nextOpenDate: string
    asapAllowed: boolean
    allowPreorders: boolean
    isOpen: boolean
    isOnline: boolean
    isOrderable: boolean
    prepTime: number
    collectionPreorderTimes: Array<string>
    hygieneRatingId: number | null
    enableHygieneRating: boolean
    isPendingTableNumbersEnabled: boolean
    pendingTableNumbersCustomText: string | null
    deliveryPaymentMethods: Array<Types.DetailedPaymentMethod>
    collectionPaymentMethods: Array<Types.DetailedPaymentMethod>
    tablePaymentMethods: Array<Types.DetailedPaymentMethod>
    availableFulfilments: Array<{
      __typename: 'AvailableFulfilment'
      fulfilmentMethod: Types.NarrowFulfilmentMethodInputType
      fulfilmentTimeTypes: Array<Types.FulfilmentTimeType>
    }>
    specialOfferFlags: {
      __typename: 'SpecialOfferFlags'
      deliveryDeal: string | null
      collectionDeal: string | null
      tableDeal: string | null
    }
    outletCuisines: Array<{ __typename: 'Cuisine'; id: string; name: string }>
    outletAddress: {
      __typename: 'Address'
      id: string
      firstLine: string
      secondLine: string | null
      thirdLine: string | null
      city: string
      postcode: string
      countryCode: Types.CountryCodeType
    }
    marketplace: { __typename: 'Marketplace'; id: string }
    openingTimesArray: Array<{
      __typename: 'OpeningTime'
      id: string
      timeSlot: number
      start: { __typename: 'DayAndTime'; id: string; day: number; time: string }
      end: { __typename: 'DayAndTime'; id: string; day: number; time: string }
    }>
    deliveryPreorderWindows: Array<{
      __typename: 'DeliveryPreorderWindow'
      start: string
      end: string
      isFull: boolean
    }>
    restaurant: {
      __typename: 'Restaurant'
      id: string
      description: string | null
      metaDescription: string | null
      metaKeywords: string | null
      deals: boolean | null
      dealsText: string | null
      name: string
      image: string | null
      enableAllergyInformation: boolean | null
      allowAddOnItems: boolean | null
      enableStackedMenu: boolean | null
    }
    outletLoyaltyCards: Array<{
      __typename: 'LoyaltyCard'
      id: string
      active: boolean
      termsAndConditions: string
      discountStrategy: Types.LoyaltyCardDiscountStrategy
      allowCashOrders: boolean
      canStampBeEarnedToday: boolean | null
      requiredStamps: number
      netTotal: number | null
      loyaltyCardIcon: string
      loyaltyCardColor: string
      loyaltyCardStamps: Array<{
        __typename: 'LoyaltyCardStamp'
        id: string
        stampClaimedOnOrder: { __typename: 'Order'; id: string } | null
      }>
      discount: {
        __typename: 'Discount'
        id: string
        name: string
        discountAmount: number | null
        discountPercentage: number | null
        customerEmail: string | null
        daysOfWeek: Array<number> | null
        customerEnrolled: boolean
        endDate: string | null
        startDate: string | null
        minimumSubtotalGross: number | null
      }
    }>
  } | null
}

export const outletFulfilmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'outletFulfilment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showInactive' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'acceptedFulfilmentMethods' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'NarrowFulfilmentMethodInputType',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentLocation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FulfilmentLocationInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outlet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showInactive' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closedUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverImage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promoteOutlet' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletLogoOverride' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryMinimumOrderValue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionMinimumOrderValue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'daysOfferedInAdvanceMin' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'daysOfferedInAdvanceMax' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'daysOfferedInAdvanceMinDelivery',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'daysOfferedInAdvanceMaxDelivery',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'daysOfferedInAdvanceMinCollection',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'daysOfferedInAdvanceMaxCollection',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethods' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'outletPhone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hiddenMenuItemIds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hiddenMenuItemGroupIds' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'availableFulfilmentInputMethods',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nowFilter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preorderDeliveryAllowed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preorderCollectionAllowed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFulfilments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilmentMethod' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilmentTimeTypes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'specialOfferFlags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryDeal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collectionDeal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tableDeal' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletCuisines' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prioritisedFulfilmentMethods' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'acceptedFulfilmentMethods',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'acceptedFulfilmentMethods',
                        },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fulfilmentLocation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fulfilmentLocation' },
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondLine' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thirdLine' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimesArray' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSlot' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextOpenDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'asapAllowed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowPreorders' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isOpen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOnline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOrderable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collectionPreorderTimes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hygieneRatingId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enableHygieneRating' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isPendingTableNumbersEnabled' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'pendingTableNumbersCustomText',
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'deliveryPaymentMethods' },
                  name: {
                    kind: 'Name',
                    value: 'outletPaymentMethodsForFulfilmentType',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'narrowFulfilmentMethod' },
                      value: { kind: 'EnumValue', value: 'DELIVERY' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'collectionPaymentMethods' },
                  name: {
                    kind: 'Name',
                    value: 'outletPaymentMethodsForFulfilmentType',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'narrowFulfilmentMethod' },
                      value: { kind: 'EnumValue', value: 'COLLECTION' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'tablePaymentMethods' },
                  name: {
                    kind: 'Name',
                    value: 'outletPaymentMethodsForFulfilmentType',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'narrowFulfilmentMethod' },
                      value: { kind: 'EnumValue', value: 'TABLE' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryPreorderWindows' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'returnFullWindows' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFull' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restaurant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metaDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metaKeywords' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'deals' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dealsText' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'enableAllergyInformation',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowAddOnItems' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enableStackedMenu' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletLoyaltyCards' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'getMarketplaceWideOnes' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termsAndConditions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountStrategy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowCashOrders' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canStampBeEarnedToday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredStamps' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'netTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardStamps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'stampClaimedOnOrder',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountAmount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'discountPercentage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'daysOfWeek' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEnrolled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'minimumSubtotalGross',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardIcon' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCardColor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  outletFulfilmentQuery,
  outletFulfilmentQueryVariables
>
